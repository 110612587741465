<template>
    <v-scroll-y-transition>
        <div class="stepContent paymentMethod">
            <debug-indicator file-name="src/components/order/PaymentMethod.vue"/>
            <!-- กล่องที่ไว้แสดงเวลามันขยายอยู่ -->
            <template v-if="isExpanded">
                <template v-if="canEdit">
                    <template v-if="false">
                        <pre>{{ inputState }}</pre>
                        order.skip_receiver_flag : {{ order.skip_receiver_flag }}
                        <br> isPassShipping : {{ (isPassShipping) ? 'true' : 'false' }}
                        <br> localIsPassShipping : {{ (localIsPassShipping) ? 'true' : 'false' }}
                        <br> orderData.is_product_no_shipping_type : {{ orderData.is_product_no_shipping_type }}
                    </template>
                    <debug-indicator file-name="src/components/order/PaymentMethod.vue"
                                     detail="isExpanded && canEdit"/>
                    <!-- กล่องน้ำเงิน ที่บอกว่าให้กลับไปกรอก BuyerMethod ก่อน -->
                    <template v-if="!localIsPassBuyer">
                        <v-alert @click="gotoBuyerInfoStep()"
                                 style="cursor: pointer;"
                                 class="mb-8"
                                 color="primary"
                                 type="info">
                            <div class="just_flex">
                                <span>{{ $t('โปรดระบุข้อมูลติดต่อก่อนนะคะ') }}</span>
                                <v-icon>mdi-arrow-up</v-icon>
                            </div>
                        </v-alert>
                    </template>
                    <!-- กล่องน้ำเงิน ที่บอกว่าให้กลับไปกรอก ShippingMethod ก่อน -->
                    <template v-else-if="!localIsPassShipping">
                        <v-alert @click="gotoPreviousStep()"
                                 style="cursor: pointer;"
                                 class="mb-8"
                                 color="primary"
                                 type="info">
                            <div class="just_flex">
                                <span>{{ $t('โปรดระบุข้อมูลจัดส่งก่อนนะคะ') }}</span>
                                <v-icon>mdi-arrow-up</v-icon>
                            </div>
                        </v-alert>
                    </template>
                    <div :class="{disabled:(!localIsPassBuyer || !localIsPassShipping)}">
                        <template v-if="!isDesktop && orderData.can_edit_coupon && orderData.order_status !== 'wait_payment'">
                            <!-- คูปองส่วนลด -->
                            <v-btn style="margin-bottom: 1rem;"
                                   outlined
                                   block
                                   x-large
                                   color="primary"
                                   @click="updateExpandOrderDetail()">
                                {{ $t('กรอกโค้ดส่วนลด (ถ้ามี)') }}
                                <template v-if="isLnwPay">/ {{ $t('ใช้คะแนน') }}</template>
                            </v-btn>
                        </template>
                        <!-- ที่จริง backend ยังอนุญาตให้แก้ไขข้อมูลใบเสร็จ ตอนยังเป็น wait_payment ได้อยู่เลย แต่ถ้าเอาข้อมูลใบเสร็จมาแสดงแทรกไว้ข้างบน มันดูแปลกๆ เลยซ่อนไว้ -->
                        <template v-if="orderData.order_status === 'wait_order' && !isZeroPriceOrder">
                            <receipt-tax-invoice :is-expanded="isExpanded"></receipt-tax-invoice>
                            <v-card v-if="isQuotationNoteEnabled"
                                    outlined
                                    class="mb-0">
                                <div class="py-4 px-4" >
                                    <v-checkbox v-model="isRequestQuote"
                                                hide-details
                                                style="font-size: 1rem">
                                        <template v-slot:label>
                                            <div>
                                                <span class="mr-2">{{ $t('ต้องการใบเสนอราคา') }}</span>
                                                <div class="opacity50 d-inline-block">({{$t('ชำระเงินภายหลัง')}})</div>
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </v-card>
                        </template>
                        <!-- กรณีที่เลือกขอใบเสนอราคาแต่เลือก cod จะต้องเปลี่ยนวิธีขนส่ง -->
                        <v-alert v-if="isRequestQuote && selected_shipping.cod_flag"
                                 type="error"
                                 class="mt-4"
                                 text>
                            {{ $t('เนื่องจากคุณเลือกต้องการใบเสนอราคา คุณจะไม่สามารถเลือกวิธีการจัดส่งแบบชำระเงินปลายทางได้ กรุณา') }}
                            <a @click="gotoPreviousStep()"><u>{{ $t('แก้ไขวิธีการจัดส่ง') }}</u></a>
                        </v-alert>
                        <template v-else>
                            <template v-if="isSelectingPaymentMethodAndProvider">
                                <v-scroll-y-transition>
                                    <div class="just_flex input-label">
                                        <span>
                                            {{ $t('เลือกวิธีชำระเงินที่คุณสะดวก') }}
                                        </span>
                                        <template v-if="canSkipPaymentMethod">
                                            <v-btn @click="skipPaymentMethod"
                                                   v-if="!inputState || inputState.selectedMethod != 'skip'"
                                                   color="primary"
                                                   depressed
                                                   text>
                                                {{ $t('เลือกภายหลัง') }}
                                            </v-btn>
                                            <v-btn @click="unSkipPaymentMethod"
                                                   v-else
                                                   color="primary"
                                                   depressed
                                                   text>
                                                {{ $t('เปลี่ยน') }}
                                            </v-btn>
                                        </template>
                                    </div>
                                </v-scroll-y-transition>
                            </template>
                            <!-- LnwPaymentComponent - กล่องแสดงตัวเลือกวิธีการชำระเงิน -->
                            <template v-if="isZeroPriceOrder"><!--กรณี 0 บาท-->
                                <v-list-item-group top>
                                    <v-radio-group :value="1"
                                                   style="margin: 5px 0 0;"
                                                   hide-details>
                                        <v-card class="mx-auto has-label allChannel"
                                                flat>
                                            <fieldset>
                                                <v-list top>
                                                    <div>
                                                        <label>
                                                            <v-list-item class="selected">
                                                                <v-list-item-action>
                                                                    <v-radio :value="1"></v-radio>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                        <div class="paymentTitle">
                                                                            <span>{{ $t('ไม่ต้องชำระเงิน') }}</span>
                                                                        </div>
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        {{ $t('เนื่องจากราคาสุทธิเท่ากับ 0 บาท') }}
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </label>
                                                    </div>
                                                </v-list>
                                            </fieldset>
                                        </v-card>
                                    </v-radio-group>
                                </v-list-item-group>
                            </template>
                            <template v-else>
                                <template v-if="selected_shipping.cod_flag"><!--กรณี COD-->
                                    <v-list-item-group top>
                                        <v-radio-group :value="1"
                                                       style="margin: 5px 0 0;"
                                                       hide-details>
                                            <v-card class="mx-auto has-label allChannel"
                                                    flat>
                                                <fieldset>
                                                    <v-list top>
                                                        <div>
                                                            <label>
                                                                <v-list-item class="selected">
                                                                    <v-list-item-action>
                                                                        <v-radio :value="1"></v-radio>
                                                                    </v-list-item-action>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>
                                                                            <div class="paymentTitle">
                                                                                <span>{{ $t('ชำระเงินปลายทาง') }}</span>
                                                                                <div v-if="selected_shipping.providers"
                                                                                     style="margin-left: 25px;">
                                                                                    <v-tooltip top
                                                                                               :key="'provider' + provider"
                                                                                               v-for="provider in selected_shipping.providers">
                                                                                        <template v-slot:activator="{ on, attrs }">
                                                                                            <img v-bind="attrs"
                                                                                                 v-on="on"
                                                                                                 :src="require('@/assets/shipping/' + provider + '.png')"
                                                                                                 alt="">
                                                                                        </template>
                                                                                        <span style="text-transform: capitalize">{{ provider }}</span>
                                                                                    </v-tooltip>
                                                                                    <!--suppress HtmlUnknownAttribute -->
                                                                                    <div v-if="selected_shipping.providers.length === 0"
                                                                                         class="provider"
                                                                                         data-provider="custom"></div>
                                                                                </div>
                                                                            </div>
                                                                        </v-list-item-title>
                                                                        <v-list-item-subtitle>
                                                                            Cash on delivery (COD)
                                                                        </v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </label>
                                                        </div>
                                                    </v-list>
                                                    <template v-if="!isShowDisabledPaymentMethodsWhenSelectedCOD">
                                                        <v-divider></v-divider>
                                                        <div>
                                                            <label>
                                                                <v-list-item @click="showDisabledPaymentMethod()">
                                                                    <v-list-item-action>
                                                                        <v-icon>mdi-chevron-down</v-icon>
                                                                    </v-list-item-action>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title style="margin-bottom: 0">
                                                                            <span style="font-weight: normal">{{ $t('แสดงวิธีการชำระเงินทั้งหมด') }}</span>
                                                                        </v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </label>
                                                        </div>
                                                    </template>
                                                </fieldset>
                                            </v-card>
                                        </v-radio-group>
                                    </v-list-item-group>
                                </template>
                                <template v-if="isShowPaymentMethods">
                                    <v-alert v-if="isShowDisabledPaymentMethodsWhenSelectedCOD"
                                             type="info"
                                             color="primary"
                                             text>
                                        {{ $t('เนื่องจากคุณเลือกวิธีการจัดส่งแบบชำระเงินปลายทาง หากต้องการชำระเงินหลังสั่งซื้อทันที กรุณา') }}
                                        <a @click="gotoPreviousStep()"><u>{{ $t('แก้ไขวิธีการจัดส่ง') }}</u></a>
                                    </v-alert>
                                    <v-form ref="form_payment"
                                            lazy-validation
                                            :class="(isShowDisabledPaymentMethodsWhenSelectedCOD)?'disabled':''">
                                        <lnw-payment-component :payment-methods="sortedPaymentConfig"
                                                               :payment-profiles="usedPaymentMethod"
                                                               v-model="inputState"/>
                                    </v-form>
                                </template>
                                <template v-if="isSkippedPaymentMethod">
                                    <v-card outlined class="mb-6 px-4 py-5">
                                        <b><v-icon color="primary"
                                                   class="mr-2">mdi-radiobox-marked</v-icon> {{ $t('เลือกวิธีชำระเงินภายหลัง') }}</b>
                                    </v-card>
                                </template>
                            </template>
                        </template>
                        <div v-if="isShowGoBackAndConfirmButton"
                             class="submitArea"
                             :class="!isDisabledConfirmButton?'ready':''">
                            <v-btn v-if="isShowGoBackButton"
                                   class="backButton"
                                   height="52"
                                   text
                                   @click="gotoPreviousStep()">
                                <div class="icon-chevron-left"></div>
                                {{ $t('กลับไปแก้ไข') }}
                            </v-btn>
                            <div v-else
                                 class="needDiv"></div>
                            <div>
                                <v-btn x-large
                                       block
                                       depressed
                                       :disabled="isDisabledConfirmButton"
                                       color="primary"
                                       class="orderConfirmCallAction"
                                       @click="gotoNextStep()">
                                    <template v-slot>
                                        <template v-if="isSelectedShippingCOD || isZeroPriceOrder">
                                            {{ $t('ยืนยันการสั่งซื้อ') }}
                                            <template v-if="hasShippingPrice">฿{{ cpNetPrice }}</template>
                                        </template>
                                        <template v-else>
                                            <template v-if="inputState && inputState.selectedMethod === 'bank_transfer'">{{ $t('ยืนยันและไปโอนเงิน') }}</template>
                                            <template v-else>{{ $t('ยืนยันและไปชำระเงิน') }}</template>
                                            <template v-if="hasShippingPrice"> ฿{{ cpNetPrice }}</template>
                                        </template>
                                    </template>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <!-- เลย state ชำระเงินไปแล้ว หรือ expired ไปแล้วเป็นต้น -->
                    <!-- เหมือนจะไม่มีเคสเข้าอันนี้เลย เพราะมันน่าจะไม่ได้ isExpanded                   -->
                    <debug-indicator file-name="src/components/order/PaymentMethod.vue"
                                     detail="isExpanded && !canEdit"/>
                    <v-card class="mx-auto"
                            elevation="24">
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <template v-if="order.payment_method">
                                            {{ $t('เลือกจ่ายด้วยวิธี') }} {{ order.payment_method }}
                                        </template>
                                        <template v-else>
                                            <template v-if="order.inform_payments && order.inform_payments.length > 0">
                                                <show-payment-inform :payment_informs="order.inform_payments"
                                                                     :is-expanded="isExpanded"></show-payment-inform>
                                            </template>
                                            <template v-else>
                                                {{ $t('ยังไม่เคยแจ้งโอนเงิน') }}
                                            </template>
                                        </template>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </template>
                <!-- กรณีที่อยู่ใน wait_payment & wait_payment_verify (ตอนนี้อยู่ใน IsExpand) จะให้แสดงและแก้ได้ -->
                <template v-if="orderData.order_status !== 'wait_order' && !isZeroPriceOrder">
                    <div class="pt-4">
                        <receipt-tax-invoice :is-expanded="isExpanded"></receipt-tax-invoice>
                    </div>
                </template>
            </template>
            <!-- กล่องที่ไว้แสดงเวลามันหุบอยู่ -->
            <template v-if="!isExpanded">
                <template v-if="isPass">
                    <v-card class="mx-auto"
                            elevation="24">
                        <!-- ถ้าไม่เป็น COD -->
                        <template v-if="!isSelectedShippingCOD">
                            <debug-indicator file-name="src/components/order/PaymentMethod.vue"
                                             detail="!isExpanded && isPass"/>
                            <template v-if="isLnwPay">
                                <v-list top>
                                    <v-list-item class="pb-0">
                                        <v-list-item-content>
                                            <div class="minor mb-2">
                                                <span>{{ $t('ยืนยันเมื่อ') }} {{ clock.diff_now_text(order.order_payment.accept_time) }}</span> ·
                                                <span class="d-inline-block minor">{{ clock.show_datetime(order.order_payment.accept_time) }}</span>
                                            </div>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <div class="minor text-right mb-2 fullOpacity chip_container">
                                                <v-chip v-if="order.order_payment.status === 'accept'"
                                                        small
                                                        color="light-green lighten-5"
                                                        text-color="success">
                                                    <v-icon x-small
                                                            class="mr-1">
                                                        mdi-check-circle
                                                    </v-icon>
                                                    {{ $t('ยืนยันแล้ว') }}
                                                </v-chip>
                                                <v-chip v-else
                                                        outlined
                                                        small
                                                        color="warning">
                                                    <v-icon x-small
                                                            class="mr-1">
                                                        mdi-clock-time-ten-outline
                                                    </v-icon>
                                                    {{ $t('รอยืนยัน') }}
                                                </v-chip>
                                            </div>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item class="pt-0">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ $t('ชำระผ่าน LnwPay') }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span>{{ $t('รายการสั่งซื้อนี้คุ้มครองโดย') }}</span>
                                                <img style="height: 15px; vertical-align: text-top; margin: 0"
                                                     alt=""
                                                     src="@/assets/lnwpay_logo_gradient.png"/>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <div class="">
                                                <span class="baht">฿</span>{{ number_format(this.order.price.pay, 2) }}
                                            </div>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </template>
                            <template v-else>
                                <show-payment-inform :payment_informs="order.inform_payments"
                                                     :payment_completed="order.order_payment"
                                                     :is-expanded="isExpanded"
                                                     :canReInformPayment="canReInformPayment"></show-payment-inform>
                            </template>
                        </template>
                        <!-- ถ้าเป็น COD -->
                        <template v-else>
                            <v-list top>
                                <v-list-item>
                                    <v-list-item-content class="mb-0">
                                        <v-list-item-title>
                                            {{ $t('ชำระเงินปลายทาง') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Cash on Delivery
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="isLnwPay"
                                                              class="mt-4">
                                            <span>{{ $t('รายการสั่งซื้อนี้คุ้มครองโดย') }}</span>
                                            <img style="height: 15px; vertical-align: text-top; margin: 0"
                                                 alt=""
                                                 src="@/assets/lnwpay_logo_gradient.png"/>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <div class="">
                                            <span class="baht">฿</span>{{ number_format(this.order.price.pay, 2) }}
                                        </div>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </template>
                        <receipt-tax-invoice :is-expanded="isExpanded"></receipt-tax-invoice>
                    </v-card>
                </template>
                <template v-else-if="orderData.order_status !== 'wait_order' && orderData.order_status !== 'wait_payment'">
                    <v-card class="mx-auto"
                            elevation="24">
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ $t('ไม่มีข้อมูลชำระเงิน') }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ $t('รายการสั่งซื้อนี้ยังไม่มีการชำระเงินที่ตรวจสอบได้') }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <receipt-tax-invoice :is-expanded="isExpanded"></receipt-tax-invoice>
                    </v-card>
                </template>
                <div v-if="!orderData.order_payment
                            && ['wait_payment', 'wait_payment_verify'].includes(orderData.order_status)
                            && isPass
                            && isProtectedByLnwPay
                            && !isSelectedShippingCOD"
                     class="minor pt-4 disabled">
                    {{
                        $t('LnwPay จะยืนยันยอดโอนภายในระยะเวลา 1 วันทำการ หากต้องการติดต่อ LnwPay กรุณาโทร 02-026-6404 ทุกวัน ยกเว้นวันหยุดนักขัตฤกษ์ เวลา 9.00 - 18.00 น.หรือ อีเมล support@LnwPay.com')
                    }}
                </div>
            </template>
        </div>
    </v-scroll-y-transition>
</template>
<script>
import {inDevelopmentKplus} from '@/libraries/Utilities'
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import MixinsFormCouponCode from "./mixins/MixinsFormCouponCode";
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import LnwPaymentComponent from "./components/PaymentMethod/LnwPaymentComponent";
import ReceiptTaxInvoice from "@/components/Order/components/PaymentMethod/ReceiptTaxInvoice.vue";
import ShowPaymentInform from "@/components/Order/components/PaymentMethod/ShowPaymentInform.vue";
import MixinsDisabledIconFocus from "@/mixins/MixinsDisabledIconFocus.vue";
import {clock} from "@/libraries/DateTime.js";
import Helper from "@/libraries/Helper";
import MixinsTheOrderDetailMutual from "@/components/Order/mixins/MixinsTheOrderDetailMutual.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper.vue";
import {formInject} from "@/libraries/FormInjection.js";
import store from "@/store";

export default {
    components: {LnwPaymentComponent, DebugIndicator, ShowPaymentInform, ReceiptTaxInvoice},
    mixins: [
        MixinsDisabledIconFocus,
        MixinsFormCouponCode,
        MixinsHelper,
        MixinsTheOrderDetailMutual,
    ],
    name: "PaymentMethod",
    props: ['isExpandOrderDetail'],
    data(){
        return {
            loader: null,
            couponLoading: false,
            conclusion: null,
            /* @todo save และเอาช่องทางที่เคยใช้มาแสดง */
            usedPaymentMethod: [],
            // usedPaymentMethod: [
            //     {key : 'credit_card',value : '4943517657200482'},
            //     {key : 'mobile_banking',value : '0807701700'},
            //     {key : 'internet_banking',value : 'chillpay_scb'},
            //     {key : 'counter_payment',value : 'payatall'},
            //     {key : 'e_wallet',value : 'paypal'},
            //     {key : 'bank_transfer',value : 'kbank'},
            // ],
            isShowDisabledPaymentMethodsWhenSelectedCOD: false, // เวลาที่เลือกขนส่ง COD อยู่ จะแสดงช่องทางชำระเงินอื่นๆ มั้ย (ที่ถูก disabled ไว้) เผื่อผู้ใช้อยากเปลี่ยนช่องทางขนส่ง เพื่อจ่ายผ่านช่องทางออนไลน์อื่นๆ แทน
        };
    },
    computed: {
        ...mapGetters({
            order: 'order/order',
            order_form: 'order/form',
            isExpanded: 'order/isExpandPaymentMethod',
            isPass: 'order/isPassStepPaymentMethod',
            isPassBuyer: 'order/isPassStepBuyerInfo',
            isPassShipping: 'order/isPassStepShippingMethod',
            isPayable: 'order/isPayable',
            isCart: 'order/isCart',
            appliedCoupons: 'order/appliedCoupons',
            paymentConfigs: 'order/paymentConfigs',
            paymentProfiles: 'order/paymentProfiles',
            isPreferToChangePaymentMethod: 'order/isPreferToChangePaymentMethod',
            isSelectingPaymentMethodAndProvider: 'order/isSelectingPaymentMethodAndProvider',
            isPaidOrPaymentInformed: 'order/isPaidOrPaymentInformed',
            canEdit: 'order/canEditPaymentMethod',
            isProtectedByLnwPay: 'order/isProtectedByLnwPay',
            isShopIsTestShop: 'order/isShopIsTestShop',
            canReInformPayment: 'order/canReInformPayment',
            stepMapping: 'order/stepMapping',
            isBankTransferPaymentProvider: 'order/isBankTransferPaymentProvider',
            canEditProductQuantity: 'order/canEditProductQuantity',
            canEditProductDetail: 'order/canEditProductDetail',
            canRequestTax: 'order/canRequestTaxInvoice', // ร้านเปิดระบบใบกำกับหรือไม่
            preferReceiptButNotSelectContact: 'order/preferReceiptButNotSelectContact',
            selected_shipping: 'order/selectedShipping',
            isPassAllStep: 'order/isPassAllStep',
            creditCardPaymentAuthURL2c2p: 'order/creditCardPaymentAuthURL2c2p',
            creditCardProvider: 'order/creditCardProvider',
            isQuotationNoteEnabled: 'order/isQuotationNoteEnabled',
        }),
        ...mapState({
            shopData: state => state.order.shopData,
            orderData: state => state.order.orderData
        }),
        sortedPaymentConfig(){
            let desiredArray = [
                'credit_card',
                'mobile_banking',
                'bank_transfer',
                'thai_qr_payment',
                'internet_banking',
                'counter_payment',
                'e_wallet'
            ];
            let sortedArray = [];
            let that = this;
            desiredArray.forEach(function (key){
                that.paymentConfigs.forEach(function (payment){
                    if(key === payment.key){
                        sortedArray.push(payment);
                    }
                });
            });
            return sortedArray;
        },
        cpNetPrice(){
            if(this.order){
                // .net คือ จำนวนเงินที่ร้านจะได้
                // .pay คือ จำนวนเงินที่ลูกค้าต้องจ่าย
                return Helper.number_format(this.order.price.pay, 2);
            }
            return '-';
        },
        inputState: {
            get(){
                return this.order_form.paymentMethodInputValue;
            },
            set(newValue){
                this.storeActionSetForm({paymentMethodInputValue: newValue});
            },
        },
        isSkippedPaymentMethod(){
            return this.inputState?.selectedMethod === 'skip' && this.order.order_status === 'wait_order';
        },
        canSkipPaymentMethod(){
            return this.order.order_status === 'wait_order' && !this.selected_shipping.cod_flag;
        },

        isShowPaymentMethods(){
            return (
                !this.isSelectedShippingCOD
                || this.isShowDisabledPaymentMethodsWhenSelectedCOD
            );
        },
        isShowGoBackAndConfirmButton(){

            if(this.isSelectingPaymentMethodAndProvider)
                return true;

            if(this.$store.getters["order/isOrderWaitPayment"] && !this.isBankTransferPaymentProvider)
                return true;

            return false;


            // return this.isPreferToChangePaymentMethod
            //     || (
            //         this.order
            //         && (
            //             this.order.order_status === 'wait_order'
            //             || (
            //                 this.order.order_status === 'wait_payment'
            //                 && (
            //                     !this.order.payment_provider // ยังไม่เลือกช่องทางชำระเงิน
            //                     || !this.isBankTransferPaymentProvider // เลือกแล้ว แต่ไม่ได้เลือก โอนเงิน
            //                 )
            //             )
            //         )
            //     );
        },
        isShowGoBackButton(){
            if(this.stepMapping.paymentMethod === 1)
                return false;

            return true;
        },
        isDisabledConfirmButton(){
            // ไม่มีสินค้าในบิล

            if(this.cpOrderItems.length <= 0){
                return true;
            }

            // ถ้า 0 บาท ไม่ต้องเช็คอยากอื่น กดยืนยันรับฟรีได้เสมอ
            if(this.isZeroPriceOrder){
                return false;
            }

            // ถ้าติ๊กเลือกว่าจะเอาใบเสร็จ แต่ยังไม่ระบุข้อมูลใบเสร็จ ไม่ให้ไปต่อ
            if(this.preferReceiptButNotSelectContact){
                return true;
            }
            // ถ้าติ๊กเลือกว่าจะเอาใบเสนอราคา แต่เลือก cod ไม่ให้ไปต่อ
            if(this.isRequestQuote && this.selected_shipping.cod_flag){
                return true;
            }

            // ถ้ายังอยู่ในสถานะ รอร้านยืนยันรายการไม่ให้ชำระเงิน
            if(this.isWaitingShippingPrice){
                return true;
            }
            if(
                this.order.payment_provider // กำหนด payment_provider ไว้ใน order แล้ว
                && this.order.payment_provider !== 'kplus' // ต้องไม่ใช่ kplus เพราะต้องกรอกเบอร์มือถือก่อน ref: 991bfef0-15c7-46b5-9c51-b76777dad225
                // && !this.isPreferToChangePaymentMethod // ** ควรเช็คด้วย isSelectingPaymentMethodAndProvider แทน
                && !this.isSelectingPaymentMethodAndProvider // ไม่ได้อยู่ใน mode ที่กำลังเลือก method ใหม่
            ){
                // ถ้าเป็น wait_order หรือ wait_payment กดยืนยันสั่งจ่ายได้
                return !['wait_order', 'wait_payment'].includes(this.order.order_status);
                // สถานะอื่น จ่ายเงินไม่ได้อยู่แล้ว
            }else{
                if(this.isSelectedShippingCOD){ // COD ไม่ต้องเลือกช่องทางชำระเงิน
                    return false;
                }else{
                    return !(this.inputState && this.inputState.ready);
                }
            }
        },
        isDisabledInputs(){
            return !this.isPassShipping;
        },
        isLnwPay(){
            //กรณนี้จะเป็น LnwPay เมื่อมีการชำระเงินแล้วเท่านั้น (ไม่ใช่ว่ากำลังจะเป็น LnwPay
            return this.order.order_payment && this.order.order_payment.channel === 'lnwpay';
        },
        is_devel(){
            return inDevelopmentKplus();
        },
        clock(){
            return clock;
        },
        canEditCart(){
            return this.canEditProductDetail || this.canEditProductQuantity;
        },
        // มันมีกรณีที่ สร้าง order จากหลังร้าน แล้วสถานะมาเป็น wait_payment เลยโดยที่ข้อมูลผู้ซื้อ ยังไม่ครบตามการตั้งค่าของร้าน
        localIsPassBuyer(){
            if(this.order.order_status === 'wait_payment')
                return true;

            return this.isPassBuyer;
        },
        // สำหรับกรณีร้านสร้างแบบ ข้ามการใส่ข้อมูลที่อยู่ได้
        localIsPassShipping(){
            if(this.order.skip_receiver_flag)
                return true;

            return this.isPassShipping;
        },
        isZeroPriceOrder(){
            return this.order.price?.pay === 0;
        },
        isSelectedShippingCOD(){
            return this.selected_shipping?.cod_flag;
        },
        isRequestQuote: {
            set(value) {
                this.$store.commit('order/setForm', {key: 'isRequestQuote', value: value});
            },
            get() {
                return this.$store.state.order.form.isRequestQuote;
            },
        },
    },
    methods: {
        ...mapMutations({
            storeActionSetForm: 'order/setForm',
        }),
        ...mapActions({
            fetchOrder: 'order/fetchOrder',
            nextStep: 'order/nextStep',
            previousStep: 'order/previousStep',
            rewindStep: 'order/rewindStep',
            expandStep: 'order/expandStep',
            savePaymentMethodAndCheckout: 'order/savePaymentMethodAndCheckout',
            acquire2c2pPaymentAuthPayload: 'lnwpay/acquire2c2pPaymentAuthPayload',
            justCheckoutWithoutPayment: 'order/confirmCheckout',
        }),
        ...mapMutations({
            setStepMetasOpen: 'order/setStepMetasOpen',
        }),
        updateExpandOrderDetail(){
            this.$emit("doExpandOrderDetail", true);
        },
        clickCouponButton(){
            this.applyCouponCode();
            this.loader = 'couponLoading';
        },
        gotoBuyerInfoStep(){
            store.dispatch('order/setIsShowOverlay', true);
            this.rewindStep({currentStep: 'paymentMethod'});
            this.scrollTo('#beginStepInfo');
            setTimeout(function (){
                store.dispatch('order/setIsShowOverlay', false);
            }, 500);
        },
        gotoPreviousStep(){
            store.dispatch('order/setIsShowOverlay', true);
            this.previousStep({currentStep: 'paymentMethod'});
            this.scrollTo('#step2');
            setTimeout(function (){
                store.dispatch('order/setIsShowOverlay', false);
            }, 500);
        },
        skipPaymentMethod(){
            this.storeActionSetForm({paymentMethodInputValue: {selectedMethod: 'skip', ready: true}})
        },
        unSkipPaymentMethod(){
            this.storeActionSetForm({paymentMethodInputValue: {selectedMethod: null, ready: false}})
            this.isRequestQuote = false;
        },
        async handleErrorFrom2c2p(errCode, errDesc){
            // https://developer.2c2p.com/docs/encryption-of-card-data-information
            let correctedErrDesc = ''; // errDesc ที่อยู่ใน docs เป็น Capitalized แต่ใน errDesc ที่ส่งมาเป็นตัวเล็ก - เทสให้ครบทุกอันไม่ได้เลยเอา desc ทั้งหมดมาจาก docs แทน
            if(errCode == 1){
                correctedErrDesc = "Card number is required";
            }else if(errCode == 2){
                correctedErrDesc = "Card number is invalid";
            }else if(errCode == 3){
                correctedErrDesc = "Expiry month is required";
            }else if(errCode == 4){
                correctedErrDesc = "Expiry month must be two numbers";
            }else if(errCode == 5){
                correctedErrDesc = "Expiry year is required";
            }else if(errCode == 6){
                correctedErrDesc = "Expiry year must be four numbers";
            }else if(errCode == 7){
                correctedErrDesc = "Card already expired(year)";
            }else if(errCode == 8){
                correctedErrDesc = "Card already expired(month)";
            }else if(errCode == 9){
                correctedErrDesc = "Expiry month is invalid";
            }else if(errCode == 10){
                correctedErrDesc = "CVV2/CVC2 is invalid";
            }else{
                correctedErrDesc = errDesc;
            }
            await store.dispatch('order/setIsAjaxLoading', false);
            await store.dispatch('order/setIsAjaxResponse', false);
            await store.dispatch('order/setIsAjaxResponse', true);
            await store.dispatch('order/setIsAjaxResponseMessage', this.$t(correctedErrDesc));
            await store.dispatch('order/setIsShowOverlay', false);
            await store.dispatch('order/setIsShowOverlayPayment', false);
        },
        async gotoNextStep(){
            if(
                this.$refs.form_payment
                && this.$refs.form_payment.validate
                && !this.$refs.form_payment.validate()
            ){
                return false;
            }

            let _2c2p_params = false;

            if(this.inputState?.selectedMethod === 'credit_card' && this.creditCardProvider === 'scb_2c2p'){
                _2c2p_params = {};
                if(this.inputState.credit_card.fillingNewCreditCard){
                    const exp = this.inputState.credit_card.exp ? this.inputState.credit_card.exp.trim() : '';
                    const match = exp.match(/(\d{2})\/?(\d{2})/);
                    if(!match){
                        // กันกรณีคน inject เพราะ ui ดักไว้ให้ก่อนแล้ว
                        alert('Error: invalid EXP');
                        return;
                    }

                    _2c2p_params.mode = 'new';
                    _2c2p_params.cardnumber = this.inputState.credit_card.number;
                    _2c2p_params.month = match[1];
                    _2c2p_params.year = '20' + match[2];
                    _2c2p_params.cvv = this.inputState.credit_card.cvv;
                    // if (window.My2c2p) { // ถ้า load lib 2c2p มาไม่สำเร็จ จะ redirect ไป lnwpay
                }else{ // by token
                    if(!this.inputState.credit_card.selectedTokenId){
                        // กันกรณีคน inject เพราะ ui ดักไว้ให้ก่อนแล้ว
                        alert('Error: token_id is required');
                        return;
                    }

                    _2c2p_params.mode = 'token';
                    _2c2p_params.cvv = this.inputState.credit_card.cvv;
                    // if (window.My2c2p) { // ถ้า load lib 2c2p มาไม่สำเร็จ จะ redirect ไป lnwpay
                }
            }

            const payload = {};

            if(this.isZeroPriceOrder){
                payload.payment_method = 'free';
                payload.payment_provider = null;
            }else if(this.isSelectedShippingCOD){
                payload.payment_method = 'cod';
                payload.payment_provider = null;
            }else{
                if(this.inputState === null)
                    return;
                payload.payment_method = this.inputState.selectedMethod;
                if(this.inputState[this.inputState.selectedMethod]){
                    payload.payment_provider = this.inputState[this.inputState.selectedMethod].provider_key;
                }
            }

            if(this.canRequestTax){
                // ถ้าไม่ได้ติ๊ก checkbox ให้ส่ง receipt_id = '' เข้าไปตอน checkout เพื่อระบุว่า จะเปลี่ยนเป็นไม่ขอใบเสร็จแทน
                if(!this.order_form.receiptCheckboxChecked){
                    payload.receipt_id = '';
                }else if(this.order.receipt_id !== false){
                    payload.receipt_id = this.order.receipt_id;
                }
            }

            const isCart = this.isCart;

            await store.dispatch('order/setIsShowOverlayPayment', true);

            try {
                let return_data = null;
                if(this.inputState?.selectedMethod === 'skip'){
                    return_data = await this.justCheckoutWithoutPayment(payload);
                    this.unSkipPaymentMethod();
                }else{
                    return_data = await this.savePaymentMethodAndCheckout(payload);
                }
                if(return_data.redirecting){
                    //
                    // ไม่ต้องทำอะไร เพราะใน src/store/modules/order.js กำลังจะสั่ง redirect ไป lnwpay
                    //
                }else if(return_data.process_2c2p){
                    if(_2c2p_params){
                        if(_2c2p_params.mode === 'new'){
                            setTimeout(() => {
                                const inputs = [
                                    {
                                        value: _2c2p_params.cardnumber,
                                        attributes: {'data-encrypt': 'cardnumber',}
                                    },
                                    {
                                        value: _2c2p_params.month,
                                        attributes: {'data-encrypt': 'month',}
                                    },
                                    {
                                        value: _2c2p_params.year,
                                        attributes: {'data-encrypt': 'year',}
                                    },
                                    {
                                        value: _2c2p_params.cvv,
                                        attributes: {autocomplete: 'off', 'data-encrypt': 'cvv',}
                                    },
                                ];
                                const form = formInject('javascript:void(0);', 'POST', inputs);
                                this.$nextTick(() => {
                                    window.My2c2p.getEncrypted(form.id, async (encryptedData, errCode, errDesc) => {
                                        // noinspection EqualityComparisonWithCoercionJS
                                        if(errCode != 0){
                                            await this.handleErrorFrom2c2p(errCode, errDesc)
                                        }else{
                                            const form_data = Object.assign({}, encryptedData);
                                            form_data.cardholderName = this.inputState.credit_card.name;
                                            form_data.remember_check = this.inputState.credit_card.remember ? '1' : '0'; // ฝั่งรับที่ backend ใช้ _payload_flag
                                            await this.submit2c2pSecuredPayload(form_data, return_data);
                                        }
                                    });
                                });
                            }, return_data.delaying);
                        }else if(_2c2p_params.mode === 'token'){
                            setTimeout(() => {
                                const inputs = [
                                    {
                                        value: _2c2p_params.cvv,
                                        type: 'password',
                                        attributes: {
                                            autocomplete: 'off',
                                            'data-encrypt': 'cvv',
                                        }
                                    },
                                ];
                                const form = formInject('javascript:void(0);', 'POST', inputs);

                                this.$nextTick(() => {
                                    window.My2c2p.getEncrypted(form.id, async (encryptedData, errCode, errDesc) => {
                                        // noinspection EqualityComparisonWithCoercionJS
                                        if(errCode != 0){
                                            await this.handleErrorFrom2c2p(errCode, errDesc);
                                        }else{
                                            const form_data = Object.assign({}, encryptedData);
                                            form_data.token_id = this.inputState.credit_card.selectedTokenId;
                                            await this.submit2c2pSecuredPayload(form_data, return_data);
                                        }
                                    });
                                });
                            }, return_data.delaying);
                        }else{
                            alert('something wrong b5c2bc8c-2356-4c7e-b102-a28b4217e39e');
                        }
                    }else{
                        alert('something wrong 11104c11-10a8-427c-997d-e6ef3864d589');
                        window.location.reload();
                    }
                }else{
                    // this.nextStep({currentStep: 'paymentMethod'});
                    if(isCart){
                        this.setStepMetasOpen({
                            1: false,
                            2: false,
                            3: false,
                        });
                        // ต้อง redirect ไปที่ /order แทน
                        await this.fetchOrder();
                    }
                    await store.dispatch('order/setIsShowOverlay', false);
                    await store.dispatch('order/setIsShowOverlayPayment', false);
                    if(!isCart){
                        if(this.isZeroPriceOrder){
                            // เปลี่ยนสถานะ
                            this.scrollTo('#order-wrapper');
                        }else{
                            // เนื่องจากไม่ได้ redirect ออกไปหน้า LnwPay เลยจะเปิดกล่องให้แสดงต่อไป
                            this.expandStep({stepName: 'paymentMethod', close_another: true});

                            if(this.isPassAllStep){
                                this.scrollTo('#bannerStatus');
                            }else{
                                // วิ่งกลับไปที่ step1 เพราะว่าย้ายฟอร์มแจ้งโอนเงินไปไว้ด้านบนแล้ว
                                this.scrollTo('#step1');
                            }

                        }
                    }
                }

            } catch(e) {
                await store.dispatch('order/setIsShowOverlay', false);
                await store.dispatch('order/setIsShowOverlayPayment', false);
            }
            if(this.inputState?.selectedMethod !== 'skip'){
                this.storeActionSetForm({
                    key: 'isPreferToChangePaymentMethod',
                    value: false,
                });
            }
        },
        number_format(num, decimal){
            return Helper.number_format(num, decimal);
        },
        showDisabledPaymentMethod(){
            this.isShowDisabledPaymentMethodsWhenSelectedCOD = true;
        },
        resetValidate(){
            if(this.$refs.form_payment)
                this.$refs.form_payment.resetValidation();
        },
        async submit2c2pSecuredPayload(form_data, return_data){
            const data = await this.acquire2c2pPaymentAuthPayload({form_data, return_data});

            if(data.scb_2c2p_payload){
                const form = formInject(this.creditCardPaymentAuthURL2c2p, 'POST', [
                    {
                        name: 'paymentRequest',
                        type: 'hidden',
                        value: data.scb_2c2p_payload,
                    }
                ]);
                await store.dispatch('order/setIsShowOverlayPayment', true);
                form.submit();
            }
        },
    },
    watch: {
        inputState(newValue){
            if(!newValue.ready){
                this.resetValidate();
            }
        },
        selected_shipping(newValue){
            // clear payment:inputState เวลามีการเลือกขนส่งที่เป็น COD
            if(newValue.cod_flag){
                this.inputState = null;
            }

            // ทุกครั้งที่ขนส่งมีการเปลี่ยนแปลง ให้ไม่ต้องแสดง ช่องทางชำระเงิน อื่นๆ อีกจนกว่าผู้ใช้จะกดเปิดมาดูอีกครั้ง
            this.isShowDisabledPaymentMethodsWhenSelectedCOD = false;
        },
        isRequestQuote(newValue) {
            if(newValue){
                this.skipPaymentMethod();
            }else{
                this.unSkipPaymentMethod();
            }
        },
    },
    mounted: function (){
        //กรณีที่ร้านเลือก COD มาแล้ว cart ยังไม่ได้ยืนยัน แต่ paymentMethod เป็น pass แล้ว ต้องกางออกมาใหม่
        if(this.isPassAllStep){
            if(this.orderData?.order_status === 'wait_order'){
                this.expandStep({stepName: 'paymentMethod', close_another: true});
                this.scrollTo('#step3');
            }
        }
    },
}
</script>
<style scoped></style>