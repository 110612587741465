<template>
    <div>
        <v-row v-if="canEditProductQuantity"
               align="center"
               class="pick-row">
            <v-col class="shrink p-0">
                <v-chip color="primary"
                        class="text-no-wrap"
                        style="min-width: 64px; justify-content: center"
                        :class="(isDesktop)?'mr-4':'mr-2'"
                        :small="isDesktop"
                        :x-small="!isDesktop">
                    <template v-if="promotion_data.type == 'addon'">
                        Add-on
                    </template>
                    <template v-else-if="promotion_data.type == 'gift'">
                        <v-icon x-small class="ml-0 mr-1">mdi-gift</v-icon> Gift
                    </template>
                    <template v-else>
                        {{ promotion_data.type }}
                    </template>
                </v-chip>
                <v-icon v-if="false"
                        color="primary"
                        class="mr-4">{{ promotion_data.type == 'addon' ? 'mdi-plus-circle-multiple' : 'mdi-gift' }}
                </v-icon>
            </v-col>
            <v-col class="grow font-weight-light text-caption p-0">
                <span>
                    <v-icon v-if="item_iid > 0" class="align-text-top" x-small>mdi-arrow-right-bottom</v-icon>
                    <v-icon v-else class="align-text-bottom mr-1" small>mdi-basket-plus</v-icon>
                    <template v-if="promotion_data.type == 'addon'">
                        {{ $t('ซื้อสินค้าราคาพิเศษ') }}
                    </template>
                    <template v-else-if="promotion_data.type == 'gift'">
                        {{ $t('ได้รับของแถมฟรี') }}
                    </template>
                    <template v-else>
                        {{ promotion_data.type }}
                    </template>
                </span>
                <span class="d-inline-block text-no-wrap">{{ $t('สูงสุด') }} {{ promotion_data.sub_item_max_quantity }} {{ $t('ชิ้น') }}</span>
            </v-col>
            <v-col class="shrink p-0">
                <component :is="isDesktop?'VDialog':'VBottomSheet'"
                           v-model="subItemsDialog"
                           scrollable
                           :max-width="isDesktop?'800px':'100%'">
                    <template v-slot:activator="{on,attrs}">
                        <div class="clickable d-inline-block primary--text text-no-wrap"
                             v-bind="attrs"
                             v-on="on">
                            <template>{{ $t('เลือก') }} ({{ sum_quantity_include_dialog }}/{{ promotion_data.sub_item_max_quantity }})</template>
                        </div>
                    </template>
                    <v-card class="cart-wrapper">
                        <v-card-title>
                            <div>
                                <template v-if="promotion_data?.type == 'addon'">
                                    {{ $t('เลือก Add-on') }}
                                </template>
                                <template v-else-if="promotion_data?.type == 'gift'">
                                    {{ $t('เลือกของแถม') }}
                                </template>
                                ({{ sum_quantity_include_dialog }}/{{ promotion_data.sub_item_max_quantity }})
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn text
                                   @click="layout = 'col'"
                                   min-width="36">
                                <div class="icon-grid"
                                     :class="(layout == 'col')?'primary--text':''"
                                     style="font-size: 1.2em; line-height: 1.1"></div>
                            </v-btn>
                            <v-btn text
                                   @click="layout = 'row'"
                                   min-width="36">
                                <div class="icon-align-justify"
                                     :class="(layout == 'row')?'primary--text':''"
                                     style="font-size: 1.45em; line-height: 1.1"></div>
                            </v-btn>
                            <v-btn icon
                                   :class="isDesktop?'ml-12':'ml-6'"
                                   @click="subItemsDialog = false">
                                <v-icon color="primary">mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <template v-if="loading">
                                <v-row v-if="layout == 'col'" class="py-4">
                                    <v-col v-for="n in isDesktop?3:2"
                                           :key="'skeleton' + n">
                                        <v-skeleton-loader class="mx-auto"
                                                           max-width="200"
                                                           type="card"></v-skeleton-loader>
                                    </v-col>
                                </v-row>
                                <template v-else>
                                    <v-row v-for="n in 3"
                                           :key="'skeleton' + n">
                                        <v-col cols="1">
                                            <v-skeleton-loader width="50"
                                                               height="50"
                                                               type="image"></v-skeleton-loader>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-skeleton-loader height="50"
                                                               type="list-item-two-line"></v-skeleton-loader>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-skeleton-loader height="50"
                                                               type="actions"></v-skeleton-loader>
                                        </v-col>
                                    </v-row>
                                </template>
                            </template>
                            <template v-else>
                                <div class="expanded">
                                    <div class="just_flex mt-4 mb-2">
                                        <div>
                                            {{ $t('สินค้าที่เข้าร่วมโปรโมชั่น') }} <b>{{ subItems.length }}</b> {{ $t('รายการ')}}
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                    <div class="items sub-items popup-items" :layout="layout">
                                        <template v-for="(item, index) in subItems">
                                            <ItemRow
                                                    :isDialog="true"
                                                    :canAddMore="sum_quantity_include_dialog < promotion_data.sub_item_max_quantity"
                                                    :changingQuantity="changingQuantity"
                                                    :key="'sub-items-' + item.id"
                                                    :item="item"
                                                    @addItem="addSubItem"
                                                    @openDialogSubItemRemark="openDialogSubItemRemark"
                                                    @deleteItem="deleteItem"
                                                    @changeQuantityDropdown="changeQuantityDropdown"
                                            />
                                            <v-divider v-if="isDesktop?(index % 3 == 2):(index % 2 == 1)" :key="'sub-items-divider' + index"></v-divider>
                                        </template>
                                    </div>

                                </div>
                            </template>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="pb-4">
                            <v-spacer></v-spacer>
                            <v-btn text
                                   class="px-4"
                                   :block="!isDesktop"
                                   color="primary"
                                   @click="subItemsDialog = false">
                                {{ $t('ปิดหน้าต่าง') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </component>
            </v-col>
        </v-row>
        <div class="items sub-items added-sub-items">
            <ItemRow
                    v-for="item in added_sub_items"
                    :showSubItemBadge="true"
                    :promotionType="promotion_data?.type"
                    :changingQuantity="changingQuantity"
                    :key="'added-sub-items-' + item.id?item.id:item.relate_id"
                    :item="item"
                    @deleteItem="openDeleteItemDialog"
                    @changeQuantityDropdown="changeQuantityDropdown"
                    @openDialogSubItemRemark="openDialogSubItemRemark"
            />
        </div>
        <!-- dialog สำหรับลบสินค้า -->
        <v-dialog v-if="deleteItemDialog != null"
                  :retain-focus="false"
                  v-model="deleteItemDialog"
                  max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('ยืนยันลบรายการ') }} ?
                </v-card-title>
                <v-card-text v-if="deleteItemDialogItem">{{ deleteItemDialogItem.title }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color=""
                           text
                           @click="deleteItemDialog = false">
                        {{ $t('ปิด') }}
                    </v-btn>
                    <v-btn color="red darken-1"
                           text
                           @click="deleteItem(deleteItemDialogItem); deleteItemDialog = false">
                        {{ $t('ยืนยันลบรายการ') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- dialog สำหรับระบุจำนวนสินค้า -->
        <v-dialog v-if="quantityDialogItem !== null"
                  v-model="quantityDialogItem"
                  max-width="290"
                  persistent
                  :retain-focus="false">
            <v-card class="quantityDialog">
                <v-card-title class="headline just_flex">
                    <div>{{ $t('จำนวนสินค้า') }}</div>
                    <div class="minor">{{ quantityDialogItem.unit }}</div>
                </v-card-title>
                <v-container>
                    <v-row>
                        <v-col md="4">
                            <v-btn height="50"
                                   v-if="quantityDialogValue == 1"
                                   depressed
                                   @click.stop="changeQuantityValue(-1)">
                                <v-icon small>mdi-trash-can-outline</v-icon>
                            </v-btn>
                            <v-btn height="50"
                                   v-else
                                   depressed
                                   @click.stop="changeQuantityValue(-1)">
                                -
                            </v-btn>
                        </v-col>
                        <v-col md="4">
                            <v-text-field class="pt-0 mt-0 center"
                                          hide-details="auto"
                                          type="number"
                                          style="text-align: center"
                                          min="1"
                                          :rules="[value => (value >= 0) || 'Required.', value => value <= quantityDialogItem.remain || $t('สินค้านี้เหลือไม่เพียงพอ'), value => (value >= 0 && sum_quantity_include_dialog <= promotion_data.sub_item_max_quantity) || $t('SUB_ITEM_EXCEED_QUANTITY')]"
                                          v-model="quantityDialogValue"
                                          required></v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-btn height="50"
                                   depressed
                                   @click="changeQuantityValue(1)">
                                +
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div v-if="orderData.is_show_remain && quantityDialogItem.remain !== 999666999"
                         class="center small"
                         :class="(quantityDialogItem.remain < 10)?'red--text':'grey--text'">
                        {{ $t('คงเหลือ') }} :
                        <template v-if="quantityDialogItem.remain !== 999666999">
                            {{ quantityDialogItem.remain }}
                        </template>
                        <template v-else>
                            {{ $t('ไม่จำกัด') }}
                        </template>
                    </div>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text
                           @click="quantityDialogItem = null; quantityDialogValue = null;">
                        {{ $t('ปิด') }}
                    </v-btn>
                    <v-btn color="primary"
                           text
                           :disabled="isAjaxLoading || (quantityDialogValue > quantityDialogItem.remain || (sum_quantity_include_dialog > promotion_data.sub_item_max_quantity && quantityDialogValue > 0))"
                           @click="dialogSubmitSubItemQuantity(quantityDialogItem, quantityDialogValue)">
                        {{ $t('ตกลง') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- dialog สำหรับระบุ remark -->
        <v-dialog v-if="remarkDialogItem !== null"
                  v-model="remarkDialog"
                  max-width="400"
                  persistent
                  :retain-focus="false">
            <v-card>
                <v-card-title class="just_flex">
                    <div class="headline">{{ $t('หมายเหตุสินค้า') }}</div>
                    <v-btn icon
                           @click="remarkDialog = false; remarkDialogItem = null;">
                        <v-icon color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>{{ $t('ข้อความถึงร้านค้าเกี่ยวกับสินค้าชิ้นนี้') }}</v-card-text>
                <v-card-text>
                    <v-badge color="grey darken-2"
                             overlap
                             :value="true"
                             :content="number_format(remarkDialogItem.quantity)"
                             style="font-weight: bold; display: inline-block"><img width="40" style="border-radius: 5px" :src="remarkDialogItem.thumbnail"
                                                                                   :alt="remarkDialogItem.name"/>
                    </v-badge>
                    <div style="max-width: 70%; display: inline-block; white-space: nowrap" class="ml-4 text-overflow">{{ remarkDialogItem.name }}</div>
                </v-card-text>
                <v-card-text class="pb-0">
                    <v-textarea outlined
                                autofocus
                                :label="$t('ข้อความถึงร้าน')"
                                rows="3"
                                v-model="remarkDialogValue"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary"
                           text
                           :disabled="isAjaxLoading"
                           @click="dialogSubmitSubItemRemark(remarkDialogItem, remarkDialogValue)">
                        {{ $t('ตกลง') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {VDialog, VBottomSheet} from 'vuetify/lib';
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import {mapActions, mapState} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import MixinsTheOrderDetailMutual from "@/components/Order/mixins/MixinsTheOrderDetailMutual";
import ItemRow from "@/components/Order/components/ItemRow";

export default {
    name: "SubItemsComponent",
    props: ['added_sub_items', 'promotion_data', 'item_iid'],
    data(){
        return {
            changingQuantity: false,
            loading: false,
            layout: 'col',
            loaded: false,
            subItemsDialog: false,
            subItems: [],
            setSubItems: [],
            quantityDialogItem: null,
            quantityDialogValue: null,
            remarkDialog: false,
            remarkDialogItem: null,
            remarkDialogValue: null,
            remarkValue: {},
            deleteItemDialog: false,
            deleteItemDialogItem: null,
        }
    },
    mixins: [
        MixinsHelper,
        MixinsTheOrderDetailMutual
    ],
    components: {
        DebugIndicator,
        VDialog,
        VBottomSheet,
        ItemRow
    },
    computed: {
        ...mapState({
            orderData: state => state.order.orderData,
            shopData: state => state.order.shopData,
            showCart: state => state.order.form.show_cart,
            isAjaxLoading: state => state.order.isAjaxLoading,
        }),
        sum_quantity_include_dialog() {
            let sum = 0;

            let item_id = this.quantityDialogItem?(this.quantityDialogItem.id?this.quantityDialogItem.id:this.quantityDialogItem.relate_id):false;
            let found = false;
            if(this.setSubItems.length){
                this.setSubItems.forEach((item) => {
                    if(item.product_id == item_id){
                        found = true;
                        sum += this.quantityDialogValue;
                    }else{
                        sum += item.q;
                    }
                });
            }
            if(!found){
                sum += this.quantityDialogValue;
            }
            return sum;
        },
    },
    methods: {
        ...mapActions({
            actionLoadPromotionSubItems: 'order/loadPromotionSubItems',
            actionSetSubItemData: 'order/setSubItemData',
        }),
        updateSubItems() {
            if(this.subItems.length){
                this.subItems.forEach((item) => {
                    this.$set(item, 'quantity', this.findSetSubItemQuantity(item));
                });
            }
        },
        async loadPromotionSubItems(){
            this.loading = true;
            const sub_items = await this.actionLoadPromotionSubItems({
                promotion_id: this.promotion_data.id
            });
            this.loaded = true;
            this.subItems = sub_items;
            this.updateSubItems();
            this.loading = false;
        },
        changeQuantityDropdown(item, quantity){
            if(quantity === 'custom'){
                this.quantityDialogValue = item.quantity;
                this.quantityDialogItem = item;
            }else{
                this.changeItemQuantity(item, quantity);
            }
        },
        openDeleteItemDialog(item){
            if(this.checkNoPermissionAndPopup()) return;
            if(item){
                this.deleteItemDialogItem = item;
                this.deleteItemDialog = true;
            }else if(this.selectedItems.length){
                this.deleteItemDialogItems = this.selectedItems;
                this.deleteItemDialog = true;
            }

        },
        deleteItem(item){
            if(this.checkNoPermissionAndPopup()) return;
            this.dialogSubmitSubItemQuantity(item, 0);
        },
        changeQuantityValue(adjust_quantity){
            let result_quantity = parseInt(this.quantityDialogValue) + parseInt(adjust_quantity);
            /*  ให้เกินได้แต่แสดง error แทน
            if(result_quantity > this.quantityDialogItem.remain){
                this.quantityDialogValue = this.quantityDialogItem.remain;
            }else*/
            if(result_quantity < 0){
                this.quantityDialogValue = 0;
            }else{
                this.quantityDialogValue = result_quantity;
            }
        },
        addSubItem(item, quantity){
            this.dialogSubmitSubItemQuantity(item, quantity);
        },
        async dialogSubmitSubItemQuantity(item, quantity){
            let item_id = item.id?item.id:item.relate_id;
            this.changingQuantity = item_id;
            let found = false;
            let change = true;
            this.setSubItems.forEach((item)=>{
                if(item.product_id == item_id){
                    if(item.q == quantity){
                        change = false;
                    }
                    item.q = quantity;
                    found = true;
                }
            })
            if(!found){
                this.setSubItems.push({
                    product_id : item_id,
                    q: quantity,
                })
            }
            if(change){
                await this.setSubItemData();
            }
            this.changingQuantity = false;
            this.quantityDialogItem = null;
            this.quantityDialogValue = null;
        },
        async setSubItemData(){
            await this.actionSetSubItemData({
                iid: this.item_iid,
                promotion_id: this.promotion_data.id,
                items: this.setSubItems,
            });
            this.updateSubItems();
        },
        findSetSubItemQuantity(item){
            let item_id = item.id?item.id:item.relate_id;
            const found = this.setSubItems.find(item => item.product_id === item_id);
            return found ? found.q : 0;
        },
        openDialogSubItemRemark(item){
            if(this.checkNoPermissionAndPopup()) return;
            this.remarkDialog = true;
            this.remarkDialogValue = item.detail;
            this.remarkDialogItem = item;
        },
        async dialogSubmitSubItemRemark(item, remark){
            await this.changeSubItemRemark(item, remark);
            this.remarkDialog = false;
            this.remarkDialogItem = null;
        },
        async changeSubItemRemark(item, remark){
            let item_id = item.id?item.id:item.relate_id;
            this.setSubItems.forEach((item) => {
                if(item.product_id === item_id){
                    if(item.d != remark){
                        item.d = remark;
                    }
                }
            });
            await this.setSubItemData();
        },
    },
    mounted(){
        this.added_sub_items.forEach((item)=>{
            this.setSubItems.push({
                product_id : item.relate_id,
                q: item.quantity,
                d: item.detail
            })
        });
        if(!this.isDesktop){
            this.layout = 'row';
        }
    },
    watch: {
        subItemsDialog(newValue){
            if(newValue){
                if(!this.loaded){
                    this.loadPromotionSubItems();
                }else{
                    this.updateSubItems();
                }
                this.quantityDialogValue = null;
            }
            document.querySelector("body").style.overflowY = newValue ? "hidden" : null;
        }
    }
}
</script>
<style scoped></style>