<template>
    <div class="stepContent buyerInfo">
        <debug-indicator file-name="src/components/order/ContactInfo.vue"/>
        <component :is="isDesktop?'VDialog':'VBottomSheet'"
                   v-model="loginDialog"
                   :persistent="isRequiredLogin"
                   :max-width="isDesktop?'600px':'100%'"
                   :transition="isDesktop?'dialog-transition':'dialog-bottom-transition'">
            <v-card>
                <div id="login-wrapper">
                    <div class="step">
                        <div class="loginRequired lnwme-container py-8 px-6">
                            <div class="stepContent buyerInfo">
                                <div class="center my-4">
                                    <img height="25"
                                         src="@/assets/lnw_new_logo.png"/>
                                    <div class="caption mt-4 grey--text">
                                        {{ $t('เพียงบัญชีเดียว ก็ใช้ได้ทุกเว็บเทพ') }}
                                    </div>
                                    <div class="caption grey--text"
                                         style="margin-top: -0.125rem;">
                                        <span style="font-size: 0.8em">{{ $t('สะดวก. รวดเร็ว. ปลอดภัย.') }}</span>
                                    </div>
                                </div>
                                <!-- 2fa - ฟอร์มสำหรับกรอก OTP จาก Authenticator -->
                                <template v-if="twoFactorToken">
                                    <v-form @submit.prevent.stop="submit2faOtp()">
                                        <div>
                                            <div class="my-5">
                                                <div class="font-weight-bold">{{ $t('การยืนยัน 2FA') }}</div>
                                                <div class="text-body-2 my-2">{{ $t('รับรหัสยืนยันด้วย Authenticator App') }}</div>
                                            </div>
                                            <v-text-field v-model="twoFactorCode"
                                                          :label="$t('OTP 6 หลัก')"
                                                          :autocomplete="false"
                                                          :maxlength="6"
                                                          class="mb-6"
                                                          hide-details="auto"
                                                          validate-on-blur
                                                          :rules="[v => !!v || $t('กรุณากรอก OTP ด้วยนะคะ'), v => (v && (v.length === 6)) || this.$t('รูปแบบไม่ถูกต้อง')]"
                                                          pattern="\d*"
                                                          outlined>
                                                <template v-slot:message="{ message }">
                                                    <span v-html="message"></span>
                                                </template>
                                            </v-text-field>
                                        </div>
                                        <div>
                                            <div>
                                                <v-btn x-large
                                                       block
                                                       depressed
                                                       type="submit"
                                                       color="primary">{{ $t('ยืนยัน') }}
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div class="minor mt-6">
                                            {{ $t('ทำไมคุณถึงต้องยืนยัน ? เนื่องจากคุณได้เปิดระบบยืนยันตัวตนแบบ 2FA (Two-Factor Authentication) ไว้เพื่อความปลอดภัยของบัญชี กรุณากรอก OTP ที่ได้รับจาก Authenticator App ของคุณ') }}
                                        </div>
                                    </v-form>
                                </template>
                                <template v-else>
                                  <v-form ref="form_login"
                                          v-model="validLoginInfo">
                                      <v-alert v-if="isRequiredLogin"
                                               text
                                               color="primary"
                                               type="info"
                                               class="mb-4"
                                               icon="mdi-login">
                                          {{ $t('จำเป็นต้องเข้าสู่ระบบเพื่อทำรายการสั่งซื้อ') }}
                                      </v-alert>
                                      <div class="just_flex mb-5">
                                          <span class="d-inline-block">{{ $t('เข้าสู่ระบบ') }}</span>
                                          <v-btn text
                                                 target="_blank"
                                                 color="primary"
                                                 :href="accountUrl('forgot_password')">{{ $t('ลืมรหัสผ่าน') }}
                                          </v-btn>
                                      </div>
                                      <div class="needDiv"
                                           style="flex-grow: 1">
                                          <v-text-field v-model.trim="formMix"
                                                        v-on:change="removeNonDigitCharacters()"
                                                        :rules="mixEmailMobileRules"
                                                        clearable
                                                        :autocomplete="(isRequiredLogin)?'email':'tel'"
                                                        v-disabled-icon-focus
                                                        outlined
                                                        persistent-hint
                                                        validate-on-blur>
                                              <template v-slot:label>
                                                  {{ $t('อีเมล') }}
                                                  <template v-if="!isRequiredLogin">
                                                      {{ $t('หรือ') }} {{ $t('เบอร์มือถือ') }}
                                                  </template>
                                              </template>
                                          </v-text-field>
                                          <v-text-field v-model="password"
                                                        ref="password"
                                                        :rules="passwordRules"
                                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="showPassword ? 'text' : 'password'"
                                                        label="รหัสผ่าน"
                                                        autocomplete="password"
                                                        v-disabled-icon-focus
                                                        @click:append="showPassword = !showPassword"
                                                        @keydown.enter="onPressEnterInPassword"
                                                        outlined>
                                              <template v-slot:message="{ message, key }">
                                                  <span v-html="message"></span>
                                              </template>
                                          </v-text-field>
                                      </div>
                                      <div>
                                          <div @click="(!canClickEnterLoginBtn)?login_validate():''">
                                              <v-btn x-large
                                                     block
                                                     depressed
                                                     @click="submitLoginWithPassword"
                                                     :disabled="!canClickEnterLoginBtn || cpIsShowLoading"
                                                     color="primary">{{ $t('ยืนยัน') }}
                                              </v-btn>
                                          </div>
                                      </div>
                                      <!-- Social login -->
                                      <v-scroll-y-transition>
                                          <template v-if="!cpFlagIsLogin">
                                              <div class="social_login_container mt-8 flex_start"
                                                   :class="(password != '')?'opacity50':''">
                                                  <div class="just_flex"
                                                       style="width: 100%">
                                                      <div class="social_login_text">
                                                          <template v-if="$i18n.locale == 'th'">{{ $t('หรือ') }}</template>
                                                          {{ $t('เข้าสู่ระบบด้วย') }}
                                                      </div>
                                                      <div class="social_login">
                                                          <div class="login_button">
                                                              <div class="facebook_login">
                                                                  <button tabindex="-1"
                                                                          type="button"
                                                                          class="button button-facebook"
                                                                          @click="SocialLogin_via_Facebook">
                                                                      <span class="logo"></span><span class="text">Facebook</span>
                                                                  </button>
                                                              </div>
                                                              <div class="line_login">
                                                                  <button tabindex="-1"
                                                                          type="button"
                                                                          class="button button-line"
                                                                          @click="SocialLogin_via_Line">
                                                                      <span class="logo"></span><span class="text">LINE</span>
                                                                  </button>
                                                              </div>
                                                              <div class="google_login">
                                                                  <button tabindex="-1"
                                                                          type="button"
                                                                          class="button button-google"
                                                                          @click="SocialLogin_via_Google">
                                                                      <span class="logo"></span><span class="text">Google</span>
                                                                  </button>
                                                              </div>
                                                              <div class="apple_login">
                                                                  <button tabindex="-1"
                                                                          type="button"
                                                                          class="button button-apple"
                                                                          @click="SocialLogin_via_Apple">
                                                                      <span class="logo"></span><span class="text">Apple</span>
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </template>
                                      </v-scroll-y-transition>
                                      <template v-if="isRequiredLogin">
                                          <v-divider class="m-4 p-0"></v-divider>
                                          {{ $t('ยังไม่ได้สมัครสมาชิก') }}? <a :href="accountUrl('register')"
                                                                               target="_blank"
                                                                               color="primary">{{ $t('สมัครบัญชีใหม่') }}</a>
                                      </template>
                                  </v-form>
                                </template>
                                <div class="mt-8 mb-4">
                                    <v-btn v-if="!isRequiredLogin"
                                           text
                                           block
                                           x-large
                                           color="primary"
                                           @click="loginDialog = false">
                                        {{ $t('ปิดหน้าต่าง') }}
                                    </v-btn>
                                    <v-btn v-else
                                           color="primary"
                                           class="backButton"
                                           height="52"
                                           text
                                           block
                                           :href="(isRealMall)?mall.url:shopData.url">
                                        <v-icon color="primary"
                                                class="mr-2">mdi-chevron-left
                                        </v-icon>
                                        {{ isDesktop?$t('เลือกซื้อสินค้าเพิ่ม'):$t('เลือกซื้อเพิ่ม') }}
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card>
        </component>
        <v-scroll-y-transition hide-on-leave>
            <div v-if="isExpanded">
                <template v-if="canEditBuyerInfo">
                    <div>
                        <!-- [กรณีที่ล็อคอินอยู่] : แสดงรูปภาพ และปุ่ม logout -->
                        <v-scroll-y-transition hide-on-leave>
                            <template v-if="cpFlagIsLogin">
                                <v-alert color="#999"
                                         text
                                         dense
                                         style="margin-top: -1rem;"
                                         class="mb-6">
                                    <div class="profile just_flex flex-wrap"
                                         style="gap: 0.5rem;">
                                        <div class="flex_wrap text-left my-2 text-body-2 grey--text text--darken-2">
                                            <v-icon small color="success">mdi-check-circle</v-icon>
                                            {{ $t('ยินดีต้อนรับ') }} <a class="primary--text"
                                                                        @click="toggleUserDrawer()">{{ userData.name }}</a>
                                        </div>
                                        <div v-if="isUserJoin"
                                             class="d-inline-block">
                                            <template v-if="orderData.user_groups && orderData.user_groups.length">
                                                <v-tooltip top
                                                           v-for="(level, index) in orderData.user_groups"
                                                           :key="index">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs"
                                                                v-on="on"
                                                                small
                                                                class="m-1"
                                                                color="primary"
                                                                :class="(orderData.user_groups.length > 1)?'mr-2':''">
                                                            {{ level }}
                                                        </v-chip>
                                                    </template>
                                                    {{ $t('ระดับสมาชิกของคุณ') }}
                                                </v-tooltip>
                                            </template>
                                            <template v-else>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs"
                                                                v-on="on"
                                                                small
                                                                class="my-1"
                                                                color="primary">
                                                            {{ $t('สมาชิกร้านทั่วไป') }}
                                                        </v-chip>
                                                    </template>
                                                    {{ $t('คุณยังไม่ได้รับระดับสมาชิกของร้านนี้') }}
                                                </v-tooltip>
                                            </template>
                                            <template v-if="isDropshipActive && orderData.dropship_level_name">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs"
                                                                v-on="on"
                                                                small
                                                                class="m-1"
                                                                dark
                                                                color="pink">
                                                            {{ orderData.dropship_level_name }}
                                                        </v-chip>
                                                    </template>
                                                    {{ $t('ระดับสมาชิกตัวแทนของคุณ') }}
                                                </v-tooltip>
                                            </template>
                                        </div>
                                        <template v-else> <!-- ยังไม่ได้ Join -->
                                            <join-dialog>
                                                <template v-slot:activator="{on,attrs}">
                                                    <v-btn v-bind="attrs"
                                                           v-on="on"
                                                           outlined
                                                           small
                                                           class="my-auto"
                                                           color="primary">
                                                        {{ $t('Join เป็นสมาชิกร้าน') }}
                                                    </v-btn>
                                                </template>
                                            </join-dialog>
                                        </template>
                                    </div>
                                </v-alert>

                            </template>
                        </v-scroll-y-transition>
                        <template v-if="cpIsShowFormFullBuyerInfo">
                            <v-form ref="form"
                                    v-model="validBuyerInfo">
                                <div class="mb-6 just_flex">
                                    <div @click="validate()">
                                        {{ $t('กรอกข้อมูลสำหรับการแจ้งอัพเดท') }}
                                        <template v-if="false && isRequiredContactMobileOrEmail">
                                            <span class="text-caption">({{ $t('กรอกอย่างใดอย่างหนึ่ง') }})</span>
                                        </template>
                                    </div>
                                    <template v-if="!cpFlagIsLogin && canSetUser">
                                        <v-btn color="primary"
                                               class="m-0 px-3"
                                               text
                                               @click="loginDialog = true">
                                            <v-icon class="mr-2">mdi-login</v-icon>
                                            {{ $t('เข้าสู่ระบบ') }}
                                        </v-btn>
                                    </template>
                                </div>
                                <div class="buyerInfoForm">
                                    <div class="needDiv"
                                         v-if="isShowFormMobileNumber"
                                         style="flex-grow: 1;">
                                        <v-text-field v-model="formMobileNumber"
                                                      :rules="mobileRules"
                                                      clearable
                                                      v-disabled-icon-focus
                                                      outlined
                                                      autocomplete="tel"
                                                      type="tel"
                                                      :required="isRequiredContactMobile"
                                                      validate-on-blur
                                                      @change="onlyNumberInput"
                                                      @keypress.enter="onPressEnterInIdentity">
                                            <template v-slot:label>
                                                {{ $t('เบอร์มือถือ') }}
                                            </template>
                                            <template v-slot:prepend-inner v-if="isShowFormEmail">
                                                <v-icon class="ml-1 mr-2">mdi-cellphone
                                                </v-icon>
                                            </template>
                                            <template v-slot:append v-if="!isShowFormEmail">
                                                <span class="text-body-2 disabled">{{ $t('เช่น') }} 0912345678</span>
                                            </template>
                                        </v-text-field>
                                    </div>
                                    <div class="needDiv"
                                         v-if="isShowFormEmail"
                                         style="flex-grow: 1;">
                                        <v-text-field v-model.trim="formEmail"
                                                      :rules="emailRules"
                                                      clearable
                                                      autocomplete="email"
                                                      v-disabled-icon-focus
                                                      outlined
                                                      validate-on-blur
                                                      :required="isRequiredContactEmail"
                                                      @keypress.enter="onPressEnterInIdentity">
                                            <template v-slot:label>
                                                {{ $t('อีเมล') }}
                                            </template>
                                            <template v-slot:prepend-inner v-if="isShowFormMobileNumber">
                                                <v-icon class="ml-1 mr-2">mdi-email-outline
                                                </v-icon>
                                            </template>
                                            <template v-slot:append v-if="!isShowFormMobileNumber">
                                                <span class="text-body-2 disabled">{{ $t('เช่น') }} example@gmail.com</span>
                                            </template>
                                        </v-text-field>
                                    </div>
                                </div>
                                <v-card outlined flat class="px-4 py-1 mb-6">
                                    <fb-checkbox-plugin ref="fbCheckboxPlugin"></fb-checkbox-plugin>
                                    <line-notify @updateLineNotifyFlag="(flag) => formLineNotifyCheck = flag" ref="lineNotify"></line-notify>
                                    <v-scroll-y-transition hide-on-leave>
                                        <div v-if="cpIsShowSMSCheckboxInFormMobile" class="needDiv">
                                            <v-checkbox class="pt-1 mt-2 pb-3"
                                                        v-model="formSmsCheck"
                                                        hide-details>
                                                <template v-slot:label>
                                                    <span style="color: rgba(0, 0, 0, .75)">{{ $t('รับ SMS อัพเดทสถานะสั่งซื้อนี้') }}</span>
                                                </template>
                                            </v-checkbox>
                                            <v-divider></v-divider>
                                        </div>
                                    </v-scroll-y-transition>
                                    <div class="just_flex py-2 mb-2">
                                        <v-checkbox v-model="formAdsCheck"
                                                    class="d-inline-block mt-0"
                                                    hide-details
                                                    style="font-size: 1rem">
                                            <template v-slot:label>
                                                <span style="color: rgba(0, 0, 0, .75)">{{ $t('รับข่าวสารโปรโมชั่นจากร้านค้า') }}</span>
                                            </template>
                                        </v-checkbox>
                                        <v-tooltip class="d-inline-block"
                                                   max-width="300"
                                                   top>
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on">
                                                    <span v-if="false" class="text--secondary text-caption mr-2">{{ $t('นี่คืออะไร') }}</span>
                                                    <v-icon dense>
                                                        mdi-help-circle-outline
                                                    </v-icon>
                                                </div>
                                            </template>
                                            {{ $t('คุณอาจได้รับ e-mail หรือ sms โปรโมชั่นหรือสิทธิพิเศษสำหรับคุณจากร้านค้า โดยคุณสามารถบอกยกเลิกได้ตลอดเวลาที่บิลออนไลน์นี้') }}
                                        </v-tooltip>
                                    </div>
                                </v-card>
                            </v-form>
                            <div class="submitArea"
                                 :class="canClickEnterBuyerInfoBtn?'ready':''">
                                <v-btn v-if="orderData.order_status === 'wait_order'"
                                       color="primary"
                                       class="backButton"
                                       height="52"
                                       text
                                       :href="(isRealMall)?mall.url:shopData.url">
                                    <v-icon color="primary"
                                            class="mr-2">mdi-chevron-left
                                    </v-icon>
                                    {{ isDesktop?$t('เลือกซื้อสินค้าเพิ่ม'):$t('เลือกซื้อเพิ่ม') }}
                                </v-btn>
                                <div @click="(!canClickEnterBuyerInfoBtn)?validate():''">
                                    <v-btn x-large
                                           block
                                           depressed
                                           @click="enterBuyerInfo"
                                           :disabled="!canClickEnterBuyerInfoBtn"
                                           color="primary">{{ $t('ถัดไป') }}
                                    </v-btn>
                                </div>
                            </div>
                        </template>
                        <!-- ต้อง JOIN ร้าน ก่อนซื้อ -->
                        <template v-else-if="cpIsShowFormJoin">
                            <v-alert text
                                     type="info"
                                     color="primary"
                                     class="mb-6"
                                     icon="mdi-login">
                                {{ $t('ร้านนี้ต้องการให้ผู้ซื้อ Join เป็นสมาชิกของร้านก่อนทำรายการสั่งซื้อ') }}
                            </v-alert>
                            <v-card outlined
                                    class="mb-6">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ $t('กรุณากด Join เป็นสมาชิกร้าน') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ $t('สมาชิกของร้านจะได้รับสิทธิพิเศษต่างๆ ตามที่ร้านกำหนด') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-avatar rounded
                                                  class="logo"
                                                  :href="shopData.url"
                                                  target="_blank">
                                            <img alt=""
                                                 :src="shopData.avatar"/>
                                        </v-avatar>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-card>
                            <div class="submitArea ready">
                                <v-btn class="backButton"
                                       height="52"
                                       text
                                       @click="editBuyerInfo">
                                    <div class="icon-chevron-left"></div>
                                    {{ $t('กลับไปแก้ไข') }}
                                </v-btn>
                                <join-dialog :gotoNextStep="gotoNextStep">
                                    <template v-slot:activator="{on,attrs}">
                                        <v-btn v-bind="attrs"
                                               v-on="on"
                                               x-large
                                               color="primary"
                                               style="vertical-align: top; font-size: 16px;">
                                            {{ $t('Join เป็นสมาชิกร้าน') }}
                                        </v-btn>
                                    </template>
                                </join-dialog>
                            </div>
                        </template>
                        <!--
                        <template v-else>
                            needMoreContactInfo: {{needMoreContactInfo}}<br/>
                            cpFlagBuyerMixInfoConfirmed: {{cpFlagBuyerMixInfoConfirmed}}<br>
                            cpFlagIsLogin: {{cpFlagIsLogin}}<br>
                            hasAnyContactInfo: {{hasAnyContactInfo}}<br>
                        </template>
                        -->
                    </div>
                </template>
                <template v-else>
                    <div>{{ $t('ไม่สามารถแก้ไขได้') }}</div>
                </template>
            </div>
        </v-scroll-y-transition>
        <template v-if="!isExpanded">
            <template v-if="isPass">
                <v-card class="mx-auto py-lg-2"
                        elevation="24"
                        style="margin-left: 30px; !important;">
                    <v-list-item>
                        <!--                        <v-list-item-avatar size="50">-->
                        <!--                            <v-avatar size="50"-->
                        <!--                                      v-if="orderData && orderData.user && orderData.user.avatar">-->
                        <!--                                <img alt="avatar"-->
                        <!--                                     :src="orderData.user.avatar"/>-->
                        <!--                            </v-avatar>-->
                        <!--                        </v-list-item-avatar>-->
                        <v-list-item-content class="pb-2">
                            <div class="">
                                <div class="py-2">
                                    <div class="just_flex_start flex_wrap pb-1"
                                         style="gap: 0.5rem;">
                                        <div class="needDiv">
                                            <v-list-item-title v-if="buyerInfo.mobile"
                                                               class="important_data_2 pb-1">
                                                {{ mobile_format(buyerInfo.mobile) }} <span v-if="formSmsCheck"><v-icon small
                                                                                                                        color="success">mdi-checkbox-marked-outline</v-icon> {{ $t('รับ') }} SMS </span>
                                            </v-list-item-title>
                                            <v-list-item-title v-if="buyerInfo.email"
                                                               class="pb-1"
                                                               :class="(!buyerInfo.mobile)?'important_data_2':''">
                                                {{ buyerInfo.email }}
                                            </v-list-item-title>
                                            <v-list-item-title v-if="!buyerInfo.email && !buyerInfo.mobile"
                                                               class="pb-1"
                                                               :class="(!buyerInfo.mobile)?'important_data_2':''">
                                                {{ $t('ไม่มีข้อมูลผู้ติดต่อ') }}
                                            </v-list-item-title>
                                        </div>
                                        <div class="needDiv">
                                            <!-- ไม่แสดงร้านค้าสร้างบิลแทนผู้ซื้อแล้ว เพราะเช็คจาก user_id ที่ login กับ user_id ของ order แทน-->
                                            <template v-if="false && orderData.owner_create_flag > 0">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs"
                                                                v-on="on"
                                                                small
                                                                class="mb-2 mr-0 pl-1"
                                                                color="#f5f5f5">
                                                            <v-icon small
                                                                    color="grey"
                                                                    class="mx-1">mdi-account-circle
                                                            </v-icon>
                                                            GUEST
                                                        </v-chip>
                                                    </template>
                                                    {{ $t('ร้านค้าสร้างบิลนี้แทนผู้ซื้อ') }}
                                                </v-tooltip>
                                            </template>
                                            <!-- ไม่ได้ login หรือ login แต่คนละคนกับเจ้าของบิล -->
                                            <template v-else-if="orderData.user_id && (!cpFlagIsLogin || orderData.user_id != userData.user_id)">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs"
                                                                v-on="on"
                                                                small
                                                                class="mb-2 mr-0 pl-1"
                                                                color="#f5f5f5">
                                                            <v-icon small
                                                                    color="grey"
                                                                    class="mx-1">mdi-account-circle
                                                            </v-icon>
                                                            ****
                                                        </v-chip>
                                                    </template>
                                                    {{ $t('กรุณาเข้าสู่ระบบเพื่อดูข้อมูลบิลสั่งซื้อนี้') }}
                                                </v-tooltip>
                                            </template>
                                            <!-- Login และเป็นเจ้าของบิล -->
                                            <template v-else-if="orderData.user_id && cpFlagIsLogin && orderData.user_id == userData.user_id">
                                                <template v-if="isUserJoin">
                                                    <template v-if="orderData.user_groups && orderData.user_groups.length">
                                                        <div class="flex_start"
                                                             style="gap: 0.5rem 0.75rem;">
                                                            <v-tooltip top
                                                                       v-for="(level, index) in orderData.user_groups"
                                                                       :key="index">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-chip v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            class="mb-2"
                                                                            color="primary">
                                                                        {{ level }}
                                                                    </v-chip>
                                                                </template>
                                                                {{ $t('ระดับสมาชิกของคุณ') }}
                                                            </v-tooltip>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-chip v-bind="attrs"
                                                                        v-on="on"
                                                                        small
                                                                        class="mb-2"
                                                                        color="primary">
                                                                    {{ $t('สมาชิกร้านทั่วไป') }}
                                                                </v-chip>
                                                            </template>
                                                            {{ $t('คุณยังไม่ได้รับระดับสมาชิกของร้านนี้') }}
                                                        </v-tooltip>
                                                    </template>
                                                    <template v-if="isDropshipActive && orderData.dropship_level_name">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-chip v-bind="attrs"
                                                                        v-on="on"
                                                                        small
                                                                        class="mb-2"
                                                                        dark
                                                                        color="pink">
                                                                    {{ orderData.dropship_level_name }}
                                                                </v-chip>
                                                            </template>
                                                            {{ $t('ระดับสมาชิกตัวแทนของคุณ') }}
                                                        </v-tooltip>
                                                    </template>
                                                </template>
                                                <template v-else> <!-- ยังไม่ได้ Join -->
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs"
                                                                    v-on="on"
                                                                    small
                                                                    class="mb-2 px-3 pl-2"
                                                                    color="#f5f5f5">
                                                                <v-icon small
                                                                        class="mx-1">mdi-account-clock
                                                                </v-icon>
                                                                {{ $t('ยังไม่ได้เป็นสมาชิกร้าน') }}
                                                            </v-chip>
                                                        </template>
                                                        {{ $t('คุณยังไม่ได้ Join เป็นสมาชิกร้านนี้') }}
                                                    </v-tooltip>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip v-bind="attrs"
                                                                v-on="on"
                                                                small
                                                                class="mb-2 mr-0 pl-1"
                                                                color="#f5f5f5">
                                                            <v-icon small
                                                                    color="grey"
                                                                    class="mx-1">mdi-account-circle
                                                            </v-icon>
                                                            GUEST
                                                        </v-chip>
                                                    </template>
                                                    {{ $t('บิลนี้สั่งซื้อในฐานะผู้ได้รับเชิญ') }}
                                                </v-tooltip>
                                            </template>
                                        </div>
                                    </div>
                                    <v-list-item-subtitle v-if="orderData.messenger_optin_user_ref"
                                                          class="mt-2"
                                                          :class="isDesktop?'flex_start':'just_flex_start'">
                                        <span><v-icon small
                                                      color="success">mdi-checkbox-marked-outline</v-icon> {{ $t('อัพเดทสถานะทาง Messenger') }}</span>
                                        <template v-if="orderData.order_status !== 'wait_order'">
                                            <v-dialog v-model="unSubscribeDialog"
                                                      max-width="290">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div class="d-inline-block pl-3 primary--text clickable"
                                                       v-bind="attrs"
                                                       v-on="on"> {{ $t('ยกเลิก') }} </div>
                                                </template>
                                                <v-card>
                                                    <v-card-title class="text-h5">
                                                        {{ $t('ยกเลิก') }} ?
                                                    </v-card-title>
                                                    <v-card-text>
                                                        {{ $t('อัพเดทสถานะรายการสั่งซื้อนี้ทาง Messenger') }}
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="primary"
                                                               text
                                                               @click="unSubscribeDialog = false">
                                                            {{ $t('ปิดหน้าต่าง') }}
                                                        </v-btn>
                                                        <v-btn color="primary"
                                                               @click="unSubscribeMessenger();">
                                                            {{ $t('บันทึก') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </template>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="orderData.allow_line_notify_flag"
                                                          class="mt-2"
                                                          :class="isDesktop?'flex_start':'just_flex_start'">
                                        <span>
                                            <v-icon small
                                                      color="success">mdi-checkbox-marked-outline</v-icon> {{ $t('อัพเดทสถานะทาง LINE') }}</span>
                                        <template v-if="orderData.order_status !== 'wait_order'">
                                            <component :is="isDesktop?'VDialog':'VBottomSheet'"
                                                       v-model="unSubscribeLineDialog"
                                                       persistent
                                                       scrollable
                                                       :max-width="isDesktop?'400px':'100%'"
                                            >
                                                <v-card>
                                                    <v-card-title class="text-h5">
                                                        {{ $t('ยกเลิก') }} ?
                                                    </v-card-title>
                                                    <v-card-text>
                                                        {{ $t('คุณต้องการยกเลิกการส่งข้อความอัพเดทสถานะออเดอร์ไปยัง Line ใช่หรือไม่') }}
                                                        <div style="height: 10px;"></div>
                                                        {{ $t('ระบบจะพาคุณไปยังหน้าตั้งค่าการเชื่อมบัญชี Line และคุณสามารถลบบัญชีที่เชื่อมต่อได้ที่หน้านั้น') }}
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="primary"
                                                            text
                                                            @click="unSubscribeLineDialog = false;"
                                                        >
                                                            {{ $t('ปิดหน้าต่าง') }}
                                                        </v-btn>
                                                        <v-btn
                                                            color="primary"
                                                            href="https://lnw.me/account/manage/setting#line_notify"
                                                            target="_blank"
                                                            @click="unSubscribeLineDialog = false;"
                                                        >
                                                            {{ $t('ใช่') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </component>
                                        </template>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="(cpFlagIsLogin && !isUserJoin) && (orderData.allow_marketing_flag || orderData.allow_ads_flag)"
                                                          class="mt-2"
                                                          :class="isDesktop?'flex_start':'just_flex_start'">
                                        <span><v-icon small
                                                      color="success">mdi-checkbox-marked-outline</v-icon> {{ $t('รับข่าวสารโปรโมชั่นจากร้านค้า') }}</span>
                                        <template v-if="orderData.order_status !== 'wait_order'">
                                            <v-dialog v-model="unConsentDialog"
                                                      max-width="290">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div class="d-inline-block pl-3 primary--text clickable"
                                                       v-bind="attrs"
                                                       v-on="on"> {{ $t('ยกเลิก') }} </div>
                                                </template>
                                                <v-card>
                                                    <v-card-title class="text-h5">
                                                        {{ $t('ยกเลิก') }} ?
                                                    </v-card-title>
                                                    <v-card-text>
                                                        {{ $t('รับข่าวสารโปรโมชั่นจากร้านค้า') }}
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="primary"
                                                               text
                                                               @click="unConsentDialog = false">
                                                            {{ $t('ปิดหน้าต่าง') }}
                                                        </v-btn>
                                                        <v-btn color="primary"
                                                               @click="setConsent(0);">
                                                            {{ $t('บันทึก') }}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </template>
                                    </v-list-item-subtitle>
                                </div>
                            </div>
                            <v-alert v-if="cpFlagIsLogin && !isUserJoin"
                                     class="mt-2"
                                     text
                                     color="#999">
                                <div class="just_flex align-center" style="gap: 10px;">
                                    <span style="font-size: 0.7em">{{ $t('สนใจเป็นสมาชิกเพื่อรับสิทธิพิเศษ') }} ?</span>
                                    <join-dialog>
                                        <template v-slot:activator="{on,attrs}">
                                            <v-btn v-bind="attrs"
                                                   v-on="on"
                                                   small
                                                   text
                                                   class="my-auto"
                                                   color="primary">
                                                {{ $t('Join เป็นสมาชิกร้าน') }}
                                            </v-btn>
                                        </template>
                                    </join-dialog>
                                </div>
                            </v-alert>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </template>
            <template v-else-if="orderData.order_status !== 'wait_order'">
                <v-card class="mx-auto py-4"
                        elevation="24">
                    <v-list>
                        <v-list-item>
                            <v-list-item-avatar size="50">
                                <v-avatar size="50"
                                          v-if="orderData && orderData.user && orderData.user.avatar">
                                    <img alt="avatar"
                                         :src="orderData.user.avatar"/>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="important_data_2">
                                    <template v-if="orderData.owner_create_flag > 0">
                                        {{ $t('ร้านค้าสร้างบิลนี้แทนผู้ซื้อ') }}
                                    </template>
                                    <template v-else>
                                        {{ $t('ไม่มีข้อมูลผู้ติดต่อ') }}
                                    </template>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ $t('หากคุณต้องการเข้ามาตรวจสอบบิลนี้อีกครั้ง กรุณาบันทึก Link นี้ไว้') }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </template>
        </template>
    </div>
</template>
<script>
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import {mapGetters, mapMutations, mapActions, mapState} from 'vuex';
import MixinsDisabledIconFocus from "@/mixins/MixinsDisabledIconFocus.vue";
import MixinsSocialLoginMethods from "@/mixins/MixinsSocialLoginMethods.vue";
import Helper from "@/libraries/Helper";
import JoinDialog from "@/components/Order/components/JoinComponent.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import SiteUrl from "@/libraries/SiteUrl.js";
import {VBottomSheet, VDialog} from "vuetify/lib/components";
import FbCheckboxPlugin from "@/components/Order/components/FbCheckboxPlugin.vue";
import LineNotify from "@/components/Order/components/lineNotify.vue";
import store from "@/store";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const mobileRegexTH = /^(\+\d{1,3}[- .]?\d{9}|0[0-9]{9})$/;
const mobileRegexEN = /^(\+\d{1,3}[- ]?)?\d{4,14}$/;

export default {
    name: "ContactInfo",
    components: {
        DebugIndicator,
        JoinDialog,
        VDialog,
        VBottomSheet,
        FbCheckboxPlugin,
        LineNotify
    },
    mixins: [MixinsDisabledIconFocus, MixinsSocialLoginMethods, MixinsHelper],
    data(){
        return {
            devel: false,
            hasAnyContactInfo: false, // มีข้อมูลติดต่อมาก่อนแล้วหรือเปล่า
            needMoreContactInfo: false,
            showLoginForm: false,
            email: '',
            mobileNumber: '',
            profilePicture: '',
            isAccountExists: null,
            password: '',
            showPassword: false,
            formMix: '',
            validLoginInfo: false,
            validBuyerInfo: false,
            mobileRules: [
                v => (!this.isRequiredContactMobile || !!v) || this.$t('กรุณากรอกเบอร์มือถือด้วยนะคะ'),
                v => (!v
                    || this.validateMobileNumber(v))
                    || this.$t('กรุณากรอกเบอร์มือถือให้ถูกต้องนะคะ'),
            ],
            emailRules: [
                v => (!this.isRequiredContactEmail || !!v) || this.$t('กรุณากรอก E-mail ด้วยนะคะ'),
                v => (!v || emailRegex.test(v)) || this.$t('กรุณากรอก E-mail ให้ถูกต้องนะคะ'),
            ],
            mixEmailMobileRules: [
                v => !!v || this.$t('กรุณากรอกข้อมูลให้ถูกต้องนะคะ'),
                v => (!v
                    || this.validateMobileNumber(v)
                    || emailRegex.test(v))
                    || this.$t('กรุณากรอกข้อมูลให้ถูกต้องนะคะ'),
            ],
            passwordRules: [
                v => !!v || this.$t('กรุณากรอกรหัสผ่านด้วยนะคะ'),
            ],
            loading: false,
            unConsentDialog: false,
            unSubscribeDialog: false,
            unSubscribeLineDialog: false,
            loginDialog: false,
            twoFactorToken: "",
            twoFactorCode: "",
            twoFactorCodeShow: false
        };
    },
    computed: {
        ...mapGetters({
            isExpanded: 'order/isExpandBuyerInfo',
            isPass: 'order/isPassStepBuyerInfo',
            isBuyerEnterBasicInfo: 'order/isBuyerEnterBasicInfo',
            cpFlagIsLogin: 'user/isLogin',
            loggedInEmail: 'user/loggedInEmail',
            canReceiveSms: 'order/canReceiveSms',
            isRequiredLogin: 'order/isRequiredLogin',
            isRequiredJoin: 'order/isRequiredJoin',
            isUserJoin: 'order/isUserJoin',
            isDropshipActive: 'order/isDropshipActive',
            isUserLogin: 'order/isUserLogin',
            isShowFormJoin: 'order/isShowFormJoin',
            isRequiredContactEmail: 'order/isRequiredContactEmail',
            isRequiredContactMobile: 'order/isRequiredContactMobile',
            canEditBuyerInfo: 'order/canEditBuyerInfo',
            isBuyerInfoHasEnoughData: 'order/isBuyerInfoHasEnoughData',
            canSetUser: 'order/canSetUser',
            canUnsetUser: 'order/canUnsetUser',
            mall: 'order/mall',
            isRealMall: 'order/isRealMall',
            gointer_enabled: 'order/gointer',
            isFacebookCheckboxPluginEnabled: 'order/isFacebookCheckboxPluginEnabled',
            facebookCheckboxPluginCheckboxState: 'order/facebookCheckboxPluginCheckboxState',
            facebookCheckboxPluginUserRef: 'order/facebookCheckboxPluginUserRef',
        }),
        ...mapState({
            orderData: state => state.order.orderData,
            shopData: state => state.order.shopData,
            userData: state => state.user.userData,
        }),
        isShowFormEmail(){
            return this.buyerInfo.email
                || this.isRequiredContactEmail
                || this.isRequiredContactMobileOrEmail
        },
        isShowFormMobileNumber(){
            return this.buyerInfo.mobile
                || this.isRequiredContactMobile
                || this.isRequiredContactMobileOrEmail
        },
        isRequiredContactMobileOrEmail(){
            return !this.isRequiredContactEmail && !this.isRequiredContactMobile
        },
        // {------------------ store -----------------------------
        formEmail: {
            set(value){
                this.$store.commit('order/setForm', {key: 'buyerInfoEmail', value: value});
            },
            get(){
                return this.$store.state.order.form.buyerInfoEmail;
            },
        },
        formMobileNumber: {
            set(value){
                this.$store.commit('order/setForm', {key: 'buyerInfoMobileNumber', value: value});
            },
            get(){
                return this.$store.state.order.form.buyerInfoMobileNumber;
            },
        },
        formSmsCheck: {
            set(value){
                this.$store.commit('order/setForm', {key: 'smsCheck', value: value});
            },
            get(){
                return this.$store.state.order.form.smsCheck;
            },
        },
        formAdsCheck: {
            set(value){
                this.$store.commit('order/setForm', {key: 'adsCheck', value: value});
            },
            get(){
                return this.$store.state.order.form.adsCheck;
            },
        },
        formLineNotifyCheck: {
            set(value){
                this.$store.commit('order/setForm', {key: 'lineNotifyCheck', value: value});
            },
            get(){
                return this.$store.state.order.form.lineNotifyCheck;
            },
        },
        // }------------------ store -----------------------------

        // {------------------ flag -----------------------------
        cpFlagIsExpanded(){
            return this.isExpanded;
        },
        cpFlagBuyerInfoConfirmed(){
            return this.$store.state.order.form.buyerInfoConfirmed;
        },
        // cpFlagBuyerMixInfoConfirmed(){
        //     return this.$store.state.order.form.buyerMixInfoConfirmed;
        // },
        cpFlagAccountExists(){
            return this.$store.state.user.accountExists.by;
        },
        accountExists(){
            return this.$store.state.user.accountExists;
        },
        userData(){
            return this.$store.state.user.userData;
        },
        cpFlagForceAsGuest(){
            return this.$store.state.order.form.forceAsGuest;
        },
        cpFlagAjaxBuyerInfoAccountExistsRunning(){
            return this.$store.state.order.form.ajaxBuyerInfoAccountExistsRunning;
        },
        cpFlagAjaxSaveBuyerInfoRunning(){
            return this.$store.state.order.form.ajaxSaveBuyerInfoRunning;
        },
        cpFlagAjaxAuthenticateByUsernamePasswordRunning(){
            return this.$store.state.order.form.ajaxAuthenticateByUsernamePasswordRunning;
        },
        // cpIsShowFormMix(){
        //     return !this.needMoreContactInfo && // ยังมีข้อมูลที่ร้านต้องการอีก
        //         // !this.cpFlagBuyerMixInfoConfirmed && // ยังไม่ได้กดยืนยันว่าจะใช้ mixForm
        //         !this.cpFlagIsLogin &&
        //         !this.hasAnyContactInfo; // ถ้าไม่มีข้อมูล email / mobile มาก่อน ให้แสดงแบบ form mix
        // },
        cpIsShowSMSCheckboxInFormMobile(){
            //เช็คเฉพาะเบอร์ไทย
            return this.canReceiveSms && mobileRegexTH.test(this.formMobileNumber);
        },
        cpIsShowFormLogin(){
            return (
                (
                    this.cpFlagAccountExists
                    || (this.isRequiredLogin && !this.isUserLogin)
                )
                && this.showLoginForm
            );
        },
        cpIsShowFormFullBuyerInfo(){
            return !this.cpFlagBuyerInfoConfirmed;
        },
        cpIsShowFormJoin(){
            return this.isShowFormJoin;
        },
        cpIsShowLoading(){
            return this.cpFlagAjaxSaveBuyerInfoRunning
                || this.cpFlagAjaxBuyerInfoAccountExistsRunning
                || this.cpFlagAjaxAuthenticateByUsernamePasswordRunning
        },

        // }------------------ flag -----------------------------
        canClickEnterLoginBtn(){
            return !(!this.formMix
                || !this.password
                || (
                    !this.validateMobileNumber(this.formMix)
                    && !emailRegex.test(this.formMix)
                ));
        },
        canClickEnterBuyerInfoBtn(){
            // noinspection RedundantIfStatementJS
            if(
                (!this.formEmail && !this.formMobileNumber)
                || (this.formMobileNumber && !this.validateMobileNumber(this.formMobileNumber))
                || (this.formEmail && !emailRegex.test(this.formEmail))
            ){
                return false;
            }else{
                return this.validBuyerInfo;
            }
        },
        buyerInfo(){
            return {
                email: this.$store.state.order.orderData.contact_email,
                mobile: this.$store.state.order.orderData.contact_mobile,
            }
        },
        buyerInfoPayloadFromFormMix(){
            const buyerInfoPayload = {
                email: null,
                mobileNumber: null,
                smsCheck: this.formSmsCheck,
                adsCheck: this.formAdsCheck,
                lineNotifyCheck: this.formLineNotifyCheck,
            };
            if(emailRegex.test(this.formMix))
                buyerInfoPayload.email = this.formMix;
            if(this.validateMobileNumber(this.formMix))
                buyerInfoPayload.mobileNumber = this.formMix;

            return buyerInfoPayload;
        },
    },
    methods: {
        ...mapMutations({
            mutationSetForm: 'order/setForm',
            mutationSetAccountExists: 'user/setAccountExists',
        }),
        ...mapActions({
            nextStep: 'order/nextStep',
            actionSaveBuyerInfo: 'order/saveBuyerInfo',
            actionConsentFlag: 'order/saveConsentFlag',
            actionForceAsGuest: 'order/forceAsGuest',
            actionAuthenticateByUsernamePassword: 'user/authenticateByUsernamePassword',
            actionVerifyOtp: 'user/verifyOtp',
            actionExpandStep: 'order/expandStep',
            actionBackToEditBuyerInfo: 'order/backToEditBuyerInfo',
            actionCheckAccountExists: 'user/checkAccountExists',
            actionLogOut: 'user/logout',
            actionClaimOrder: 'order/claimOrder',
            actionUnclaimedOrder: 'order/unclaimedOrder',
            actionSetMessengerOptInUserRef: 'order/setMessengerOptInUserRef',
            actionFacebookCheckboxPluginConfirmOptIn : 'order/facebookCheckboxPluginConfirmOptIn'
        }),

        // async enterMixInfo(){
        //     if(this.canClickEnterLoginBtn){
        //         // this.mutationSetForm({key: 'buyerMixInfoConfirmed', value: true});
        //         if(this.cpFlagIsLogin){
        //             const success = await this.saveBuyerInfoFromFormMix();
        //             if(success && this.isPass){
        //                 await this.actionClaimOrder();
        //                 this.gotoNextStep();
        //             }
        //         }else{
        //             const buyerInfoPayload = this.buyerInfoPayloadFromFormMix;
        //             await this.actionCheckAccountExists(buyerInfoPayload);
        //             if(this.cpFlagAccountExists){
        //                 this.showLoginForm = true;
        //                 if(this.isRequiredLogin){
        //                     this.$nextTick(() => {
        //                         this.$refs.password.focus();
        //                     });
        //                 }
        //             }else{
        //                 if(this.isRequiredLogin){
        //                     this.showLoginForm = true;
        //                     this.$nextTick(() => {
        //                         this.$refs.password.focus();
        //                     });
        //                 }else{
        //                     // check if mixInfo is enough
        //                     const success = await this.saveBuyerInfoFromFormMix();
        //                     if(success && this.isPass){
        //                         this.actionForceAsGuest();
        //                         this.gotoNextStep();
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // },
        async enterBuyerInfo(){
            if(this.canClickEnterBuyerInfoBtn){
                await store.dispatch('order/setIsShowOverlay', true);
                this.formMix = ''; //เพื่อกรณีที่ลูกค้ากดเบอร์มือถือมาก่อนใน mix แล้วร้านบังคับต้องกรอกอีเมล แล้วใน force เข้า if(formMix)
                const buyerInfoPayload = {
                    email: this.formEmail,
                    mobileNumber: this.formMobileNumber,
                    smsCheck: this.formSmsCheck,
                    adsCheck: this.formAdsCheck,
                    lineNotifyCheck: this.formLineNotifyCheck,
                };

                if(this.isFacebookCheckboxPluginEnabled){
                    if(this.facebookCheckboxPluginCheckboxState === 'checked'){
                        buyerInfoPayload.messengerOptInUserRef = this.facebookCheckboxPluginUserRef;
                        // ไม่ยิงอีกรอบแล้ว เพราะ component ไม่มี window.fb จะหายไป
                        // await this.actionFacebookCheckboxPluginConfirmOptIn();
                    }else if(this.facebookCheckboxPluginCheckboxState === 'unchecked'){
                        buyerInfoPayload.messengerOptInUserRef = null;
                    }
                }
                if(this.cpFlagIsLogin){
                    if(parseInt(this.orderData.user.user_id) !== parseInt(this.userData.user_id)){
                        await this.actionClaimOrder();
                    }
                    await this.actionSaveBuyerInfo(buyerInfoPayload);

                }else{
                    await this.actionSaveBuyerInfo(buyerInfoPayload);
                    this.mutationSetForm({key: 'buyerInfoAccountExists', value: null});
                    this.actionForceAsGuest();
                }
                if(this.isBuyerInfoHasEnoughData){
                    this.mutationSetForm({key: 'buyerInfoConfirmed', value: true});
                }

                if(this.isPass){
                    this.gotoNextStep();
                }else{
                    if(this.cpIsShowFormJoin){
                        this.showFormJoin();
                    }
                }
            }
        },
        async saveBuyerInfoFromFormMix(){
            const buyerInfoPayload = this.buyerInfoPayloadFromFormMix;
            const has_enough_info =
                (!this.isRequiredContactEmail || emailRegex.test(this.formMix))
                && (!this.isRequiredContactMobile || this.validateMobileNumber(this.formMix));
            if(has_enough_info){
                await this.actionSaveBuyerInfo(buyerInfoPayload);
                return true;
            }else{
                // copy mix data to specific info input
                if(buyerInfoPayload.email)
                    this.formEmail = buyerInfoPayload.email;
                if(buyerInfoPayload.mobileNumber)
                    this.formMobileNumber = buyerInfoPayload.mobileNumber;
                // show form
                this.needMoreContactInfo = true;
                return false;
            }
        },
        async submitLoginWithPassword(){
            if(this.canClickEnterLoginBtn){
                // if(this.cpFlagIsLogin){
                //     const success = await this.saveBuyerInfoFromFormMix();
                //     if(success && this.isPass){
                //         await this.actionClaimOrder();
                //         this.loginDialog = false;
                //         this.gotoNextStep();
                //     }
                // }else{
                let payload;
                // if(this.accountExists.by === 'email'){
                //     payload = {
                //         username: formMixMode ? this.formMix : this.formEmail,
                //         password: this.password,
                //     };
                //     if(formMixMode)
                //         email = this.formMix;
                // }else if(this.accountExists.by === 'mobile'){
                //     payload = {
                //         username: formMixMode ? this.formMix : this.formMobileNumber,
                //         password: this.password,
                //     };
                //     if(formMixMode)
                //         mobile = this.formMix;
                // }
                payload = {
                    username: this.formMix,
                    password: this.password,
                };

                const success = await this.actionAuthenticateByUsernamePassword(payload);
                if (success === true) {
                  await this.afterAuthenticate();
                } else if (success && typeof success === "object" && success.hasOwnProperty('twoFactorToken')) {
                  this.twoFactorToken = success.twoFactorToken
                } else {
                  //console.log('wrong');
                }
                this.password = '';
                ///////

                // }
            }
        },
        async submit2faOtp() {
          const success = await this.actionVerifyOtp({
            twoFactorToken: this.twoFactorToken,
            twoFactorCode: this.twoFactorCode,
          })
          if (success) {
            await this.afterAuthenticate()
          }else{
              this.twoFactorCode = null;
          }
        },
        async afterAuthenticate() {
          this.mutationSetAccountExists({});
          this.loginDialog = false;
          // await this.saveBuyerInfoFromFormMix();
          if(this.userData){
            // console.log(this.formMix);
            if(emailRegex.test(this.formMix)){
              this.formEmail = this.formMix;
            }else if(this.buyerInfo.email && this.buyerInfo.email !== 'false'){
              this.formEmail = this.buyerInfo.email;
            }else if(this.userData.email){
              this.formEmail = this.userData.email;
            }
            if(this.validateMobileNumber(this.formMix)){
              this.formMobileNumber = this.formMix;
            }else if(this.buyerInfo.mobile && this.buyerInfo.mobile !== 'false'){
              this.formMobileNumber = this.buyerInfo.mobile;
            }else if(this.userData.mobile){
              this.formMobileNumber = this.userData.mobile;
            }
            await this.$nextTick();
            this.validate();
          }
        },
        async unSubscribeMessenger(){

            let payload = {};
            payload.user_ref = null;
            // console.log(payload.user_ref);
            await this.actionSetMessengerOptInUserRef(payload);
            this.unSubscribeDialog = false;
        },
        async setConsent(flag){
            await this.actionConsentFlag({
                adsCheck: flag,
            });
            this.unConsentDialog = false;
        },
        async logout(){
            // ถ้าเราเป็นเจ้าของ order อยู่ ตอนที่ logout เราจะยิงไปเอาตัวเองออกจาก order ด้วย
            if(this.canUnsetUser){
                await this.actionUnclaimedOrder();
            }
            await this.actionLogOut();
            this.editBuyerInfo();
        },
        editBuyerInfo(){
            this.mutationSetAccountExists({});
            this.showLoginForm = false;
            this.actionForceAsGuest({flag: false});
            this.actionBackToEditBuyerInfo();
            this.actionExpandStep({stepName: 'buyerInfo', close_another: true});
            this.scrollTo('#step1');
        },
        showFormJoin(){
            store.dispatch('order/setIsShowOverlay', false)
            this.actionExpandStep({stepName: 'buyerInfo', close_another: true});
            this.scrollTo('#step1');
        },
        gotoNextStep(){
            store.dispatch('order/setIsShowOverlay', true)
            this.nextStep({currentStep: 'buyerInfo', vm: this})
                .then(() => {
                    store.dispatch('order/setIsShowOverlay', false)
                });
        },
        // -----------------------------------
        isValidEmailFormat(email){
            return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
        },
        // onlyNumberPress(evt){
        //     if(evt){
        //         const charCode = (evt.which) ? evt.which : evt.keyCode;
        //         if((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46){
        //             evt.preventDefault();
        //         }else{
        //             return true;
        //         }
        //         this.onlyNumberInput();
        //     }
        // },
        onlyNumberInput(){
            if(!this.formMobileNumber){
                return
            }
            const filtered = this.formMobileNumber.replace(/[^0-9+]/g, '');
            if(filtered !== this.formMobileNumber)
                this.formMobileNumber = filtered;


        },
        // onPressEnterInMixForm(){
        //     if(this.canClickEnterLoginBtn){
        //         this.enterMixInfo();
        //     }else{
        //         event.target.blur();
        //         event.preventDefault();
        //         return false;
        //     }
        // },
        onPressEnterInIdentity(){
            if(this.canClickEnterBuyerInfoBtn){
                this.enterBuyerInfo();
            }else{
                event.target.blur();
                event.preventDefault();
                return false;
            }
        },
        onPressEnterInPassword(){
            this.submitLoginWithPassword();
        },
        mobile_format(num){
            return Helper.mobile_format(num);
        },
        validateMobileNumber(number){
            if(this.orderData.lang_key == 'th'){
                return mobileRegexTH.test(number);
            }else{
                return mobileRegexEN.test(number);
            }
        },
        validate(){
            this.$refs.form.validate();
        },
        login_validate(){
            this.$refs.form_login.validate();
        },
        removeNonDigitCharacters: function(){
            if(!this.formMix){
                return
            }
            let num = this.formMix.replace(/[^0-9]+/g, '');
            if(this.validateMobileNumber(num)){
                this.formMix = this.formMix.replace(/[^0-9]+/g, '');
            }
        },
        accountUrl(uri){
            return SiteUrl.account(uri);
        },
        toggleUserDrawer(){
            this.$emit("doToggleDrawer", true);
        }
    },
    mounted(){
        // if(this.orderData){
        //     if(this.orderData.contact_email || this.orderData.contact_mobile){
        //         this.hasAnyContactInfo = true;
        //     }
        // }
        if((!this.orderData || this.orderData.order_status === 'wait_order') && this.isRequiredLogin && !this.cpFlagIsLogin){
            this.loginDialog = true;
        }
    },
    watch: {
        loginDialog(newValue){
            // เผื่อว่ามีการกรอก email , mobile ในช่องไปก่อน แล้วไปกด login ทีหลัง
            if(newValue){
                if(!this.formMix){
                    if(this.formEmail){
                        this.formMix = this.formEmail;
                    }else if(this.formMobileNumber){
                        this.formMix = this.formMobileNumber;
                    }
                }
            }
            else {
              // ถ้ากดปิด dialog ที่ให้ใส่ otp ให้เคลีย state เพื่อกลับไปเริ่มล็อคอินใหม่
              if (this.twoFactorToken) {
                this.twoFactorToken = ''
                this.twoFactorCode = ''
                this.password = ''
              }
            }
        },
    }
}
</script>
<style scoped></style>